import PropTypes from 'prop-types'
import React, {  useState } from "react"
import { Modal, ModalBody, Col,
  Row,
  Card,
  CardBody, Label  } from "reactstrap";
  //import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"

import { format, parseISO  } from 'date-fns';

const ConfirmarModal = ({ show, onConfirmarClick, onCloseClick, itemName, fechapago, onChangeFechaPago, formapago, onChangeFormaPago }) => {

  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <i className="bx bx-dollar-circle"></i>
            </div>
          </div>
          <p className="font-size-16 mb-3">¿Estás seguro {itemName}?</p>
          <Row>
            <Col xl={6} sm={6} xs={12}>
              <div className="mt-2 mb-4">
                <h5 className="font-size-14 mb-2">Forma de Pago</h5>
                <div className="form-check  mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    onChange={e => {
                    onChangeFormaPago(e);
                  }}
                    onClick={e => {
                    onChangeFormaPago(e);
                  }}
                    name="exampleRadios"
                    id="efectivo"
                    value="efectivo"
                    checked={formapago == 'efectivo'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="efectivo"
                  >
                    Efectivo
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="transferencia"
                    onChange={e => {
                    onChangeFormaPago(e);
                  }}
                    onClick={e => {
                    onChangeFormaPago(e);
                  }}
                    value="transferencia"
                    checked={formapago == 'transferencia'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="transferencia"
                  >
                    Transferencia o Depósito
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="debito"
                    onChange={e => {
                    onChangeFormaPago(e);
                  }}
                    onClick={e => {
                    onChangeFormaPago(e);
                  }}
                    value="debito"
                    checked={formapago == 'debito'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="debito"
                  >
                    T. Débito
                  </label>
                </div>
                <div className="form-check mb-2">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="exampleRadios"
                    id="credito"
                    onChange={e => {
                    onChangeFormaPago(e);
                  }}
                    onClick={e => {
                    onChangeFormaPago(e);
                  }}
                    value="credito"
                    checked={formapago == 'credito'}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="credito"
                  >
                    T. Crédito
                  </label>
                </div>
              </div>
            </Col>
            <Col xl={6} sm={6} xs={12}>
              <div className="mt-2 mb-4">
                <h5 className="font-size-14 mb-2">Fecha de Pago</h5>
                <div className="form-floating mb-2">
                <Flatpickr
                id="fecha_pago"
                value={fechapago}
                name="fecha_pago"
                onChange={fecha => {
                  onChangeFechaPago(fecha);
                }}
                placeholder="dd M,yyyy"
                className="form-control d-block"
                options={{
                  altInput: true,
                  altFormat: "j F, Y",
                  dateFormat: "Y-m-d",
                   locale: {
                    ...Spanish,
                        months: {
                            ...Spanish.months,
                            ...Spanish.longhand
                        }
                    },
                }}
              />
              <Label htmlFor="fecha_pago">Fecha de Pago</Label>    
    
                </div>
                </div>
            </Col>
        </Row>
          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-danger" onClick={onConfirmarClick}>Confirmar</button>
            <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Cerrar</button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

ConfirmarModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmarClick: PropTypes.func,
  show: PropTypes.any,
  fechapago: PropTypes.any,
  onChangeFechaPago: PropTypes.func,
  formapago: PropTypes.any,
  onChangeFormaPago: PropTypes.func
}

export default ConfirmarModal
