// src/components/filter.
import React, { useMemo, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

//import components
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableContainer from 'components/Common/TableContainer';

import Switch from "react-switch";
import Select from "react-select";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { SketchPicker } from "react-color";
import ColorPicker from "@vtaits/react-color-picker";

import DeleteModal from "components/Common/DeleteModal";
//Import Flatepicker
//import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"
import withRouter from "components/Common/withRouter";


import {validate as Validate, clean, format as Format} from 'rut.js'
import { format, parseISO  } from 'date-fns';

import {
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Tooltip,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Spinner,
  Badge,
  Modal,
  UncontrolledPopover,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Label,
  Form,
  Input,
  InputGroup,
  UncontrolledTooltip
} from "reactstrap";

const CursosList = props => {

  const clearToast = () => {
    toastr.clear();
  }

    const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Si
      </div>
    );
  };
  
   const InactiveSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  
  const ActiveSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Si
      </div>
    );
  };
    
    const [modal_standard, setmodal_standard] = useState(false);
    const [courses, setCourses] = useState([]);
    const [loadingTable, setLoadingTable] = useState(true);
    const [loadingNew, setLoadingNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [sorting, setSorting] = useState([{ id: 'name', desc: false }]);
    const [paginaInicio, setPaginaInicio] = useState(0);
    const [filters, setFilters] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);
    const [contact, setContact] = useState();
    const [itemName, setItemName] = useState('');
    const [profesores, setProfesores] = useState([]);
    
    const jsonString = (localStorage.getItem("authUser") && localStorage.getItem("authUser").length > 0) ? JSON.parse(JSON.parse(localStorage.getItem("authUser"))[0].rol) : [];
    
    /*datos curso*/
    const [formValues, setFormValues] = useState({
        id: 0,
        nombre: '',
        inicio: '',
        fin: '',
        profesor: 0,
        price: 0,
        m_price: 0,
        active: true
      });
    
    const handleDateChange = (selectedDates, field) => {
        setFormValues({
          ...formValues,
          [field]: selectedDates,
        });
      };

      const handleCheckChange = (event) => {
       const { name } = event.target; 
        setFormValues({
          ...formValues,
          [name]: event.target.checked
        });
      };
      
      const handleSwitchChange = (event,field) => {
        setFormValues({
          ...formValues,
          [field]: event,
        });
      };
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
          ...formValues,
          [name]: value,
        });
    };
    
    const tog_standardEdit = () => {
        setEdit(true);
        setmodal_standard(!modal_standard);
        removeBodyCss();
    };

    const tog_standard = () => {

        axios.get(`/users-profesores`)
            .then(
                (res) => {
                    
                    setFormValues({
                        id: 0,
                        nombre: '',
                        inicio: '',
                        fin: '',
                        profesor: 0,
                        price: 0,
                        m_price: 0,
                        active: true
                    });
     
                    setEdit(false);
                    setmodal_standard(!modal_standard);
                    removeBodyCss();
                    setProfesores(res.data);
                })
        .catch((error) => {
            setFormValues({
                id: 0,
                nombre: '',
                inicio: '',
                fin: '',
                profesor: 0,
                price: 0,
                m_price: 0,
                active: true
            });
            setEdit(false);
            setmodal_standard(!modal_standard);
            removeBodyCss();
            if (error.response) {
                if(Array.isArray(error.response.data.message)){
                    for(let i=0;i<error.response.data.message.length;i++){
                        toastr.error(error.response.data.message[i], 'Error')
                    }
                } else {
                   toastr.error(error.response.data.message, 'Error')
                }
              } else if (error.request) {
                  toastr.error(error.request, 'Error')  
              } else {
               toastr.error(error.message, 'Error')  
              }
         });
    };
    
     const onClickDelete = courses => {
        setItemName('curso '+courses.name)
        setContact(courses);
        setDeleteModal(true);
    };

     const handleDeleteUser = () => {

        if (contact && contact.id) {
            let coursesTmp = [];
            axios.delete(`/courses`, {data : contact})
            .then(
                (res) => {
                    if(!res.data || res.data.error){
                        toastr.error('Error interno al eliminar curso', 'Error')
                    } else {
                       setCourses([]); 
                       for(let i=0;i<res.data.courses.length;i++){
                            coursesTmp.push(
                                {
                                    "id": res.data.courses[i].id,
                                    "name": res.data.courses[i].name,
                                    "inicio": format(parseISO(res.data.courses[i].inicio), 'dd/MM/yyyy'),
                                    "fin": format(parseISO(res.data.courses[i].fin), 'dd/MM/yyyy'),
                                    "profesor": res.data.courses[i].profesor,
                                    "creado": format(parseISO(res.data.courses[i].created_at), 'dd-MM-yyyy H:mm'),
                                    "activo": (res.data.courses[i].active == 1) ? 'SI' : 'NO',
                                    "price": res.data.courses[i].monthly_price,
                                    "m_price": res.data.courses[i].inscription_price,
                               }
                            );
                        }
                        setCourses(coursesTmp);
                        setLoadingNew(false);
                        setContact();
                        setDeleteModal(false)
                        setPaginaInicio(0);  
                        toastr.success(res.data.message, 'OK');
                        //tog_standard();
                        setFormValues({
                            id: 0,
                            nombre: '',
                            inicio: '',
                            fin: '',
                            profesor: 0,
                            price: 0,
                            m_price: 0,
                            active: true
                        });
                      }
                })
            .catch((error) => {
                setLoadingNew(false);
                if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                   toastr.error(error.message, 'Error')  
                  }
             })
        }
      };
    
    const guardarNuevo = (e) => {
        
        e.preventDefault();
        toastr.options = {
            closeButton: true,
            progressBar: true,
            showEasing: 'linear',
            hideEasing: 'linear',
            hideDuration: 1
          };
        toastr.clear();
        setLoadingNew(true);
        
        let coursesTmp = [];

        axios.post(`/courses`, formValues)
            .then(
                (res) => {

                    if(!res.data || res.data.error){
                        toastr.error('Error interno al crear curso', 'Error')
                    } else {
                       setCourses([]); 
                       for(let i=0;i<res.data.courses.length;i++){
                            coursesTmp.push(
                                {
                                    "id": res.data.courses[i].id,
                                    "name": res.data.courses[i].name,
                                    "inicio": format(parseISO(res.data.courses[i].inicio), 'dd/MM/yyyy'),
                                    "fin": format(parseISO(res.data.courses[i].fin), 'dd/MM/yyyy'),
                                    "profesor": res.data.courses[i].profesor,
                                    "creado": format(parseISO(res.data.courses[i].created_at), 'dd-MM-yyyy H:mm'),
                                    "activo": (res.data.courses[i].active == 1) ? 'SI' : 'NO',
                                    "price": res.data.courses[i].monthly_price,
                                    "m_price": res.data.courses[i].inscription_price,
                               }
                            );
                        }
                        setCourses(coursesTmp);
                        setLoadingNew(false);
                        toastr.success(res.data.message, 'OK');
                        tog_standard();
                        setFormValues({
                            id: 0,
                            nombre: '',
                            inicio: '',
                            fin: '',
                            profesor:0,
                            price: 0,
                            m_price: 0,
                            active: true
                        });
                      }
                })
            .catch((error) => {
                setLoadingNew(false);
                if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                   toastr.error(error.message, 'Error')  
                  }
             })

    };
    
     const actualizar = (e) => {
        
        e.preventDefault();
        toastr.options = {
            closeButton: true,
            progressBar: true,
            showEasing: 'linear',
            hideEasing: 'linear',
            hideDuration: 1
          };
        toastr.clear();
        setLoadingNew(true);
        
        let coursesTmp = [];

        axios.put(`/courses`, formValues)
            .then(
                (res) => {

                    if(!res.data || res.data.error){
                        toastr.error('Error interno al actualizar curso', 'Error')
                    } else {
                       setCourses([]); 
                       for(let i=0;i<res.data.courses.length;i++){
                            coursesTmp.push(
                                {
                                    "id": res.data.courses[i].id,
                                    "name": res.data.courses[i].name,
                                    "inicio": format(parseISO(res.data.courses[i].inicio), 'dd/MM/yyyy'),
                                    "fin": format(parseISO(res.data.courses[i].fin), 'dd/MM/yyyy'),
                                    "profesor": res.data.courses[i].profesor,
                                    "creado": format(parseISO(res.data.courses[i].created_at), 'dd-MM-yyyy H:mm'),
                                    "activo": (res.data.courses[i].active == 1) ? 'SI' : 'NO',
                                    "price": res.data.courses[i].monthly_price,
                                    "m_price": res.data.courses[i].inscription_price
                               }
                            );
                        }
                        setCourses(coursesTmp);
                        setLoadingNew(false);
                        toastr.success(res.data.message, 'OK');
                        tog_standard();
                        setFormValues({
                            id: 0,
                            nombre: '',
                            inicio: '',
                            fin: '',
                            profesor:0,
                            price: 0,
                            m_price: 0,
                            active: true
                        });
                      }
                })
            .catch((error) => {
                setLoadingNew(false);
                if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                   toastr.error(error.message, 'Error')  
                  }
             })

    };
    
    const onFocusRut = (rut, name) => 
    {
        if (rut != ''){
            rut = clean(rut);
            setFormValues({
                ...formValues,
                [name]: rut,
              });
        }
    }
    
    const refreshTable = () => {
        setLoadingTable(true);
        setCourses([]);
        let coursesTmp = [];
        axios.get('/courses', {}).then((response) => {
            if(!response.data){
            } else {
               for(let i=0;i<response.data.length;i++){
                    coursesTmp.push(
                        {
                           "id": response.data[i].id,
                           "name": response.data[i].name,
                           "inicio": format(parseISO(response.data[i].inicio), 'dd/MM/yyyy'),
                           "fin": format(parseISO(response.data[i].fin), 'dd/MM/yyyy'),
                           "profesor": response.data[i].profesor,
                           "creado": format(parseISO(response.data[i].created_at), 'dd-MM-yyyy H:mm'),
                           "activo": (response.data[i].active == 1) ? 'SI' : 'NO',
                           "price": response.data[i].monthly_price,
                           "m_price": response.data[i].inscription_price
                       }
                    );
                }
               setCourses(coursesTmp);
               setLoadingTable(false);
            }
          }, (error) => {
        });  
    }
    
    const onBlurRut = (rut, name) => 
    {
        if (rut != '') {
            if (rut.length > 3) {
                rut = Format(rut);
                setFormValues({
                ...formValues,
                [name]: rut,
              });
            }
        } 
    } 
    
    const rowClick = (cell) => 
    {   
        let id = cell.row.cells[0].value;
        setLoadingTable(true);
        axios.get('/courses/'+id ,{}).then((response) => {
               if(!response.data || response.data.error){
                    toastr.error('Error interno al obtener información', 'Error');
                    setLoadingTable(false);
               } else {
                   let curso = response.data[0];
                   let nombre = curso.name;

                   axios.get(`/users-profesores`)
                    .then(
                        (res) => {

                            setFormValues({
                                 id: curso.id,
                                 nombre: nombre,
                                 inicio: curso.inicio,
                                 fin: curso.fin,
                                 profesor: curso.apoderado_id,
                                 price: curso.monthly_price,
                                 m_price: curso.inscription_price,
                                 active: (curso.active == 1) ? true : false
                             }); 
                             setProfesores(res.data);
                             setLoadingTable(false);
                             tog_standardEdit();
                            
                        })
                    .catch((error) => {
                    setLoadingTable(false);
                    toastr.error('Error interno al obtener información', 'Error')
                    if (error.response) {
                        if(Array.isArray(error.response.data.message)){
                            for(let i=0;i<error.response.data.message.length;i++){
                                toastr.error(error.response.data.message[i], 'Error')
                            }
                        } else {
                           toastr.error(error.response.data.message, 'Error')
                        }
                      } else if (error.request) {
                          toastr.error(error.request, 'Error')  
                      } else {
                       toastr.error(error.message, 'Error')  
                      }
                 });
                   
                   

                  }
            }, (error) => {
                 setLoadingTable(false);
                 toastr.error('Error interno al obtener información', 'Error')
           });  
        
    }
    
    const handleFilterChange = (columnId, value) => {
   
        setFilters({
          ...filters,
          [columnId]: value,
        });

        // Establece el filtro en la tabla
        setFilter(columnId, value);
      };

    
    const handleColumnSort = (column) => {

        let newSort = [];
        if(!column.isSorted && typeof column.isSortedDesc === 'undefined'){
             newSort = [{ id: column.id, desc: false }];
        }else if ( column.isSorted && column.isSortedDesc === false){
             newSort = newSort = [{ id: column.id, desc: true }];
        }else {
            newSort = newSort = [{ id: 'name', desc: false }];
        }
        setPaginaInicio(0);
        setSorting(newSort);
    };
    
    const handleCambioPagina = (valor) => {
        setPaginaInicio(valor);
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
      };
    
      useEffect(() => {
          
        if(jsonString.indexOf('administrador') === -1){
            props.router.navigate('/pages-404');
            return;
        }   
    
        let coursesTmp = [];
        axios.get('/courses', {}).then((response) => {
               if(!response.data || response.data.error){
                   toastr.error('Error interno al obtener información', 'Error')
               } else {
                  for(let i=0;i<response.data.length;i++){
                       coursesTmp.push(
                           {
                            "id": response.data[i].id,
                            "name": response.data[i].name,
                            "inicio": format(parseISO(response.data[i].inicio), 'dd/MM/yyyy'),
                            "fin":  format(parseISO(response.data[i].fin), 'dd/MM/yyyy'),
                            "profesor": response.data[i].profesor,
                            "creado": format(parseISO(response.data[i].created_at), 'dd-MM-yyyy H:mm'),
                            "activo": (response.data[i].active == 1) ? 'SI' : 'NO',
                            "price": response.data[i].monthly_price,
                            "m_price": response.data[i].inscription_price
                            
                          }
                       );
                   }
                  setCourses(coursesTmp);
                  setLoadingTable(false);
               }
             }, (error) => {
                  toastr.error('Error interno al obtener información', 'Error')
           });  
     }, []);
     
    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id'
            },
            {
                Header: 'Nombre',
                accessor: 'name'
            },
            {
                Header: 'Inicio',
                accessor: 'inicio'
            },
            {
                Header: 'Fin',
                accessor: 'fin'
            },
            {
                Header: 'Profesor',
                accessor: 'profesor'
            },
            {
                Header: 'Creado',
                accessor: 'creado'
            },
            {
                Header: 'Activo',
                accessor: 'activo'
            },
            {
                Header: 'Acciones',
                accessor: 'action',
                disableFilters: true,
                filterable: false,
                Cell: (cellProps) => {
                  return (
                    <div className="d-flex gap-3">
                        <div
                        style={{cursor:'pointer'}} 
                        className="text-success"
                        onClick={() => { rowClick(cellProps); }}
                      >
                        <i style={{cursor:'pointer'}}  className="mdi mdi-pencil font-size-18" id="edittooltip" />
                        <UncontrolledTooltip placement="top" target="edittooltip">
                          Editar
                        </UncontrolledTooltip>
                      </div>
                      <div
                        style={{cursor:'pointer'}} 
                        className="text-danger"
                        onClick={() => {
                          const orderData = cellProps.row.original;
                          onClickDelete(orderData);
                        }}
                      >
                        <i style={{cursor:'pointer'}}  className="mdi mdi-delete font-size-18" id="deletetooltip" />
                        <UncontrolledTooltip placement="top" target="deletetooltip">
                          Eliminar
                        </UncontrolledTooltip>
                      </div>
                    </div>
                  );
                }
              },
        ],
        []
    );

    document.title = "Cursos | Listado";
    //console.log(courses);

    return (
            
        <div className="page-content">
           <DeleteModal
            show={deleteModal}
            itemName={itemName}
            onDeleteClick={handleDeleteUser}
            onCloseClick={() => setDeleteModal(false)}
          />
            <Container fluid={true}>
                <Breadcrumbs title="Cursos" breadcrumbItem="Listado" />
                <Row>
            <Col className="col-12">
              <Card>
                <CardBody >
                  <CardTitle className="h4">Listado de Cursos</CardTitle>
                  {/*courses.length <= 0 ||*/ loadingTable  &&  
                  <div style={{ height: '65vh' }}  className="textoCentrado spinner-content-new" >
                  <Spinner className="ms-2" color="primary" />
                  </div>
                  }
                  { !loadingTable  &&
                  <div style={{ display: !loadingTable ? '' : 'none' }} className="table-hover">
                    <TableContainer
                      columns={columns}
                      data={courses}
                      handleRefreshClick={() => { refreshTable(); }}
                      handleUserClick={() => { tog_standard(); }}
                      isAddUserList={true}
                      customPageSizeOptions={true}
                      isGlobalFilter={false}
                      isAddOptions={false}
                      isRefresh={true}
                      sortParams={sorting}
                      handleFilterChange={(e,val) => { handleFilterChange(e,val); }}
                      handleColumnSort={(e) => { handleColumnSort(e); }}
                      createText="Crear nuevo"
                      customPageSize={10}
                      className="custom-header-css"
                      rowClick={false}
                      handleCambioPagina={(e)=> { handleCambioPagina(e); }}
                      paginaInicio={paginaInicio}
                      handleRowClick={(e) => { rowClick(e); }}
                      />
                  </div>
                 }
                 <Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard();
                      }}
                      keyboard={false}
                      backdrop={loadingNew ? 'static' : true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModal">
                         {!edit ? 'Crear nuevo curso' : 'Editar curso'}
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setEdit(false);
                            setmodal_standard(false);
                          }}
                          disabled={loadingNew}
                          className="btn-close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                        </button>
                      </div>
                      <div className="modal-body">
                        <Form>
                            <div className="form-floating mb-3">
                              <input disabled={loadingNew} name="nombre" onChange={handleChange} value={formValues.nombre} type="text" className="form-control" id="nombreInput" placeholder="Ingrese nombree" />
                              <label htmlFor="nombreInput">Nombre</label>
                            </div>
                            <div className="form-floating mb-3">
                              <input disabled={loadingNew} name="m_price" onChange={handleChange} value={formValues.m_price} type="text" className="form-control" id="m_price" placeholder="Ingrese valor" />
                              <label htmlFor="m_price">Valor Matrícula</label>
                            </div>
                            <div className="form-floating mb-3">
                              <Flatpickr
                                id="inicioInput"
                                disabled={loadingNew}
                                onChange={e => {
                                    handleDateChange(e,'inicio');
                                  }}
                                value={formValues.inicio}
                                name="inicio"
                                placeholder="dd M,yyyy"
                                className="form-control d-block"
                                options={{
                                  altInput: true,
                                  altFormat: "j F, Y",
                                  dateFormat: "Y-m-d",
                                   locale: {
                                    ...Spanish,
                                        months: {
                                            ...Spanish.months,
                                            ...Spanish.longhand
                                        }
                                    },
                                }}
                              />
                              <Label htmlFor="inicioInput">Fecha Inicio</Label>
                          </div>
                           <div className="form-floating mb-3">
                              <Flatpickr
                                id="finInput"
                                disabled={loadingNew}
                                onChange={e => {
                                    handleDateChange(e,'fin');
                                  }}
                                value={formValues.fin}
                                name="fin"
                                placeholder="dd M,yyyy"
                                className="form-control d-block"
                                options={{
                                  altInput: true,
                                  altFormat: "j F, Y",
                                  dateFormat: "Y-m-d",
                                  locale: {
                                    ...Spanish,
                                        months: {
                                            ...Spanish.months,
                                            ...Spanish.longhand
                                        }
                                    },
                                }}
                              />
                              <Label htmlFor="finInput">Fecha Fin</Label>
                          </div>
                          <Row>
                            <Col md={12}>
                                <div className="form-floating mb-3">
                                  <select disabled={loadingNew} name="profesor" onChange={handleChange} value={formValues.profesor} id="profesor" className="form-select">
                                    <option value="0">Seleccione</option>
                                      {profesores && profesores.map((val, key) => (
                                      <option key={key} value={val.id}>{val.name}</option>
                                      ))}
                                  </select>
                                  <label htmlFor="profesor">Profesor</label>
                                </div>
                              </Col>
                            </Row>
                          <div className="form-floating mb-3">
                              <input disabled={loadingNew} name="price" onChange={handleChange} value={formValues.price} type="text" className="form-control" id="price" placeholder="Ingrese valor" />
                              <label htmlFor="price">Valor Mensualidad</label>
                            </div>
                            <Row>
                              <Col md={12}>
                                <FormGroup className="mb-3">
                                        <Label
                                          htmlFor="activeInput"
                                          className="form-label"
                                        >
                                          Activo
                                        </Label>
                                        <Switch
                                    id="activeInput"
                                    disabled={loadingNew}
                                    uncheckedIcon={<InactiveSymbol />}
                                    checkedIcon={<ActiveSymbol />}
                                    name="active"
                                    className="input-group me-1 mb-sm-8 mb-2"
                                    onColor="#02a499"
                                    onChange={e => {
                                        handleSwitchChange(e,'active');
                                      }}
                                    checked={formValues.active}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                      </div>
                      <div className="modal-footer">
                      <div style={{
                            display: 'inline-block',
                            alignItems: 'center',}}>
                        <div style={
                            {margin: 10,
                            position: 'relative',}}>
                        <button
                          disabled={loadingNew}  
                          type="button"
                          onClick={() => {
                            setEdit(false);
                            setmodal_standard(false);
                          }}
                          className="btn btn-secondary me-2"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        {!edit ? (
                        <button
                          disabled={loadingNew}
                          type="button"
                          className="btn btn-primary "
                          onClick={e => {
                            guardarNuevo(e);
                          }}
                        >
                        <span style={{ visibility: !loadingNew ? 'visible' : 'hidden' }}>Guardar</span>
                        </button>)
                        : (<button
                          disabled={loadingNew}
                          type="button"
                          className="btn btn-info "
                          onClick={e => {
                            actualizar(e);
                          }}
                        >
                        <span style={{ visibility: !loadingNew ? 'visible' : 'hidden' }}>Actualizar</span>
                        </button>)}
                        <Spinner style={{ display: loadingNew ? 'block' : 'none',
                        color: 'red',
                        '--bs-spinner-width':'1.7rem',
                        '--bs-spinner-height':'1.7rem',
                        position: 'absolute',
                        top: '46%',
                        left: '59%',
                        marginTop: -12,
                        marginLeft: -12}}  className="ms-2 " color="primary" />
                      </div>
                       </div>
                        </div>
                    </Modal>
                </CardBody>
                </Card>
                </Col >
                 </Row>
            </Container>
        </div>
    );
}
CursosList.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default withRouter(CursosList);