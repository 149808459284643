// src/components/filter.
import React, { useMemo, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withRouter from "components/Common/withRouter";
import pdf from "../../assets/images/pdf.png";

//import components
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableContainer from 'components/Common/TableContainer';

import Switch from "react-switch";
import Select from "react-select";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { SketchPicker } from "react-color";
import ColorPicker from "@vtaits/react-color-picker";

import ConfirmarModal from "components/Common/ConfirmarModal";
import DeleteCuotaModal from "components/Common/DeleteCuotaModal";
import ConfirmarModalEdit  from "components/Common/ConfirmarModalEdit";
//Import Flatepicker
//import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"


import {validate as Validate, clean, format as Format} from 'rut.js'
import { format, parseISO  } from 'date-fns';

import {
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Tooltip,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Spinner,
  Badge,
  Modal,
  UncontrolledPopover,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Label,
  Form,
  Input,
  InputGroup,
  UncontrolledTooltip
} from "reactstrap";

const CuotasList = props => {
      
  const clearToast = () => {
    toastr.clear();
  }

    const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Si
      </div>
    );
  };
  
   const InactiveSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  
  const ActiveSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Si
      </div>
    );
  };
    
    const [modal_standard, setmodal_standard] = useState(false);
    const [installments, setInstallments] = useState([]);
    const [installmentsok, setInstallmentsok] = useState([]);
    const [loadingTable, setLoadingTable] = useState(true);
    const [loadingNew, setLoadingNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [sorting, setSorting] = useState([{ id: 'alumno', desc: false }]);
    const [paginaInicio, setPaginaInicio] = useState(0);
    const [filters, setFilters] = useState({});
    const [confirmarModal, setConfirmarModal] = useState(false);
    const [confirmarEditModal, setConfirmarModalEdit ] = useState(false);
    const [deleteCuotaModal, setDeleteCuotaModal] = useState(false);
    const [contact, setContact] = useState();
    const [itemName, setItemName] = useState('');
    const [cuotaspagar, setCuotaspagar] = useState([]);
    const [cuotaspagarmonto, setCuotaspagarMonto] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [viendoPendientes, setViendoPendientes] = useState(true);
    
    const [modal_cancelado, setmodal_cancelado] = useState(false);
    const [modal_pagado_ok, setmodal_pagado_ok] = useState(false);
    const [mensajeOkTbk, setmensajeOkTbk] = useState('');
    const [modal_pagado_error, setmodal_pagado_error] = useState(false);
    const [mensajeErrorTbk, setmensajeErrorTbk] = useState('');

    const [cuotaspagarok, setCuotaspagarok] = useState([]);
    const [cuotaspagarmontook, setCuotaspagarMontook] = useState([]);
    const [checkAllok, setCheckAllok] = useState(false);
    
    const [fechapago, setFechapago] = useState(new Date());
    const [formapago, setFormapago] = useState('');
    
    const [cuota_editar, setCuotaEditar] = useState('');
    const [monto_editar, setMontoEditar] = useState(0);
    const [observacion_editar, setObservacionEditar] = useState('');
    const [cuota_id_editar, setCuotaIdEditar] = useState(0);
    
    const [isAdmin, setIsAdmin] = useState(false);
    const [rutBuscar, setRutBuscar] = useState('');
    
    const jsonString = (localStorage.getItem("authUser") && localStorage.getItem("authUser").length > 0) ? JSON.parse(JSON.parse(localStorage.getItem("authUser"))[0].rol) : [];
    
    /*datos cuota*/
    const [formValues, setFormValues] = useState({
        cuotaspagar: [],
        forma_pago: '',
        fecha_pago: '',
        cuotaspagarmonto: []
      });
    
    const handleDateChange = (selectedDates, field) => {
        setFormValues({
          ...formValues,
          [field]: selectedDates,
        });
      };
 
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
          ...formValues,
          [name]: value,
        });
    };
    
    const cambiarVista = () => {
        setViendoPendientes(!viendoPendientes);
    };
    
    const tog_standard = () => {

        const elem = [...cuotaspagar];
        const cuotamonto = [...cuotaspagarmonto];
        let totalPagar = 0;
        cuotamonto.forEach(monto => {
            totalPagar = totalPagar + monto;
        });
        
        setFormValues({
            cuotaspagar: [...cuotaspagar],
            forma_pago: '',
            fecha_pago: '',
            monto_total: '$ '+formatNumber(totalPagar),
            cuotaspagarmonto: [...cuotaspagarmonto]
        });
        
        //setmodal_standard(!modal_standard);
        setItemName('que confirmas pagar '+elem.length+' cuota(s), por un total de $ '+formatNumber(totalPagar))
        //setContact(setFormValues);
        setConfirmarModal(true);
        removeBodyCss();
    };
   
    const PopupCenter = (url, title, w, h) => {
            
        // Fixes dual-screen position                         Most browsers      Firefox  
        var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;  
        var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;  

        var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;  
        var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;  

        var left = ((width / 2) - (w / 2)) + dualScreenLeft;  
        var top = ((height / 2) - (h / 2)) + dualScreenTop;  

        var byteCharacters = atob(url);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);

        var newWindow = window.open(fileURL, title, 'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);  

        // Puts focus on the newWindow  
        if (window.focus) {  
            newWindow.focus();  
        }  
    };
    
    const descargaBoleta = (e,id) => {
        setLoadingTable(true);
        axios.get('/installments/boleta/'+id, {})
        .then(
            (res) => {
                    /*var a = document.createElement("a"); //Create <a>
                    a.href = res.data;
                    a.download = "Liquidacion_mensual_"+Date.now()+".pdf"; //File name Here
                    a.click(); //Downloaded file*/

                setLoadingTable(false);
                let base64 = res.data.pdf;
                PopupCenter(base64,"Boleta_"+Date.now()+".pdf",'900','800');

              
          }).catch(error => {
            setLoadingTable(false);
            if (error.response) {
                if(Array.isArray(error.response.data.message)){
                    for(let i=0;i<error.response.data.message.length;i++){
                        toastr.error(error.response.data.message[i], 'Error')
                    }
                } else {
                   toastr.error(error.response.data.message, 'Error')
                }
              } else if (error.request) {
                  toastr.error(error.request, 'Error')  
              } else {
                toastr.error(error.message, 'Error')  
              }
        });
        
        
        
    };
    
    const pagarModal = (e) => {
        if(isAdmin){
            tog_standard();
        } else {
            
            setLoadingTable(true);
            const elementos = [...cuotaspagar];
            axios.put('/installments/payment/', {'ids' : elementos}).then((response) => {
                if(!response.data){
                    toastr.error('No se logró comunicar con transbank, favor intente nuevamente', 'Error')  
                } else {

                    const formulario = document.createElement('form');
                    formulario.setAttribute('id', 'payment');
                    formulario.setAttribute('method', 'post');
                    formulario.setAttribute('action', response.data.url);
                    formulario.setAttribute('style', 'display:none');
                    
                    const token_ws = document.createElement('input');
                    token_ws.setAttribute('type', 'hidden');
                    token_ws.setAttribute('name', 'token_ws');
                    token_ws.setAttribute('value', response.data.token_ws);

                    formulario.appendChild(token_ws);
                    document.body.appendChild(formulario);
                    formulario.submit();
                    return true;
    
                }
              }, (error) => {
                  setLoadingTable(false);
                  if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                    toastr.error(error.message, 'Error')  
                  }
            });  
        }
    };
    
    const eliminarModal = (e) => {
        if(isAdmin){
            tog_standard_delete();
        } else {
            toastr.error('Opción aún no habilitada', 'Error')  
        }
    };
    
    const tog_standard_delete = () => {

        const elem = [...cuotaspagarok];
        const cuotamontook = [...cuotaspagarmontook];
        let totalPagar = 0;
        cuotamontook.forEach(monto => {
            totalPagar = totalPagar + monto;
        });
        
        setFormValues({
            cuotaspagarok: [...cuotaspagarok],
            monto_total: '$ '+formatNumber(totalPagar),
            cuotaspagarmontook: [...cuotaspagarmontook]
        });
        
        //setmodal_standard(!modal_standard);
        setItemName('que confirmas eliminar '+elem.length+' cuota(s) pagadas, por un total de $ '+formatNumber(totalPagar))
        //setContact(setFormValues);
        setDeleteCuotaModal(true);
        removeBodyCss();
    };
    
    const handleDeleteCuota = () => {
        
        if(isAdmin && rutBuscar === ""){
            toastr.error('Debes ingresar un RUT para eliminar un pago.', 'Error');
            return;
        }
        
        const elementos = [...cuotaspagarok];
        if (elementos && elementos.length > 0) {
            setLoadingNew(true);
            let installmentsTmp = [];
            let installmentsTmpOk = [];
            
            let rutS = '';
        
            if(rutBuscar != ''){
                if(hasNumber(rutBuscar)) {
                    rutS = clean(rutBuscar);
                } else {
                    rutS = rutBuscar;
                }
            }
            
           // setLoadingTable(true);
            axios.delete(`/installments/`+rutS, {data : {'ids' : elementos}})
            .then(
                (response) => {
                    if(!response.data || response.data.error){
                        toastr.error('Error interno al eliminar cuota(s)', 'Error');
                    } else { 
                       setInstallments([]);
                       for(let i=0;i<response.data.installments.length;i++){
                            installmentsTmp.push(
                                {
                                 "id": response.data.installments[i].id,
                                 "cuota": response.data.installments[i].cuota,
                                 "rut":  (response.data.installments[i].rut_alumno != "") ? Format(response.data.installments[i].rut_alumno) : '',
                                 "alumno": response.data.installments[i].alumno,
                                 "curso": response.data.installments[i].curso,
                                 "fecha_cuota":  format(parseISO(response.data.installments[i].fecha_cuota), 'dd/MM/yyyy'),
                                 "fecha_vencimiento": format(parseISO(response.data.installments[i].fecha_vencimiento), 'dd/MM/yyyy'),
                                 "cuota_valor": 
                                        (response.data.installments[i].con_matricula === 0) ?     
                                        "$ "+formatNumber(response.data.installments[i].cuota_valor) : "$ "+formatNumber(response.data.installments[i].cuota_valor)+" (c/matrícula)"
                                 ,
                                 "cuota_int" : parseInt(response.data.installments[i].cuota_valor),
                                 "apoderado": (response.data.installments[i].rut_apoderado != "") ? Format(response.data.installments[i].rut_apoderado)+' '+response.data.installments[i].apoderado : '',
                                 "observacion" : response.data.installments[i].observacion_cuota
                               }
                            );
                        }
                        setInstallmentsok([]);
                        for(let i=0;i<response.data.installments_ok.length;i++){
                            installmentsTmpOk.push(
                                {
                                 "id": response.data.installments_ok[i].id,
                                 "cuota": response.data.installments_ok[i].cuota,
                                 "rut":  (response.data.installments_ok[i].rut_alumno != "") ? Format(response.data.installments_ok[i].rut_alumno) : '',
                                 "alumno": response.data.installments_ok[i].alumno,
                                 "curso": response.data.installments_ok[i].curso,
                                 "fecha_cuota":  format(parseISO(response.data.installments_ok[i].fecha_cuota), 'dd/MM/yyyy'),
                                 "updated_at": response.data.installments_ok[i].updated_at !== '0000-00-00' && response.data.installments_ok[i].updated_at !== '' ?  format(parseISO(response.data.installments_ok[i].updated_at), 'dd/MM/yyyy H:mm') : '',
                                 "fecha_vencimiento": format(parseISO(response.data.installments_ok[i].fecha_vencimiento), 'dd/MM/yyyy'),
                                 "cuota_valor": 
                                        (response.data.installments_ok[i].con_matricula === 0) ?     
                                        "$ "+formatNumber(response.data.installments_ok[i].cuota_valor) : "$ "+formatNumber(response.data.installments_ok[i].cuota_valor)+" (c/matrícula)"
                                 ,
                                 "cuota_int" : parseInt(response.data.installments_ok[i].cuota_valor),
                                 "apoderado": (response.data.installments_ok[i].rut_apoderado != "") ? Format(response.data.installments_ok[i].rut_apoderado)+' '+response.data.installments_ok[i].apoderado : '',
                                 "observacion" : response.data.installments_ok[i].observacion_cuota
                               }
                            );
                        }
                        setInstallments(installmentsTmp);
                        setInstallmentsok(installmentsTmpOk);
                        setLoadingNew(false);
                        setDeleteCuotaModal(false)
                        setPaginaInicio(0);  
                        setCheckAllok(false);
                        setCuotaspagarok([]);
                        toastr.success(response.data.message, 'OK');
                      }
                })
            .catch((error) => {
                setLoadingNew(false);
               // setLoadingTable(false);
                if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                   toastr.error(error.message, 'Error')  
                  }
             })
        }
      };
      
      
      const handleConfirmarEditCuota  = () => {
          
          if(!isAdmin){
            toastr.error('No tienes permiso para realizar está acción.', 'Error');
            return;
            }
            
            if(monto_editar == ''){
                toastr.error('Monto no puede ser vacío.', 'Error');
                return;
            }
            
            let rutS = '';
        
            if(rutBuscar != ''){
                if(hasNumber(rutBuscar)) {
                    rutS = clean(rutBuscar);
                } else {
                    rutS = rutBuscar;
                }
            }
            setLoadingTable(true);
            let installmentsTmp = [];
            let installmentsTmpOk = [];
            //setLoadingTable(true);
            axios.post(`/installmentsupdate`, {cuota_id_editar,monto_editar, rutS, observacion_editar})
            .then(
                (response) => {
                    if(!response.data || response.data.error){
                        toastr.error('Error interno al editar cuota', 'Error');
                    } else {
           
                        setCuotaspagar([]);
                        setCuotaspagarMonto([]);
                        setCheckAll(false);
                        setCheckAllok(false);
                        setCuotaspagarok([]);
                        setCuotaspagarMontook([]);
          
                         setInstallments([]); 
                         for(let i=0;i<response.data.installments.length;i++){
                              installmentsTmp.push(
                                  {
                                   "id": response.data.installments[i].id,
                                   "cuota": response.data.installments[i].cuota,
                                   "rut":  (response.data.installments[i].rut_alumno != "") ? Format(response.data.installments[i].rut_alumno) : '',
                                   "alumno": response.data.installments[i].alumno,
                                   "curso": response.data.installments[i].curso,
                                   "fecha_cuota":  format(parseISO(response.data.installments[i].fecha_cuota), 'dd/MM/yyyy'),
                                   "fecha_vencimiento": format(parseISO(response.data.installments[i].fecha_vencimiento), 'dd/MM/yyyy'),
                                   "cuota_valor": 
                                          (response.data.installments[i].con_matricula === 0) ?     
                                          "$ "+formatNumber(response.data.installments[i].cuota_valor) : "$ "+formatNumber(response.data.installments[i].cuota_valor)+" (c/matrícula)"
                                   ,
                                   "cuota_int" : parseInt(response.data.installments[i].cuota_valor),
                                   "apoderado": (response.data.installments[i].rut_apoderado != "") ? Format(response.data.installments[i].rut_apoderado)+' '+response.data.installments[i].apoderado : '',
                                   "observacion" : response.data.installments[i].observacion_cuota
                                 }
                              );
                          }
                          setInstallmentsok([]);
                          for(let i=0;i<response.data.installments_ok.length;i++){
                              installmentsTmpOk.push(
                                  {
                                   "id": response.data.installments_ok[i].id,
                                   "cuota": response.data.installments_ok[i].cuota,
                                   "rut":  (response.data.installments_ok[i].rut_alumno != "") ? Format(response.data.installments_ok[i].rut_alumno) : '',
                                   "alumno": response.data.installments_ok[i].alumno,
                                   "curso": response.data.installments_ok[i].curso,
                                   "fecha_cuota":  format(parseISO(response.data.installments_ok[i].fecha_cuota), 'dd/MM/yyyy'),
                                   "updated_at": response.data.installments_ok[i].updated_at !== '0000-00-00' && response.data.installments_ok[i].updated_at !== '' ?  format(parseISO(response.data.installments_ok[i].updated_at), 'dd/MM/yyyy H:mm') : '',
                                   "fecha_vencimiento": format(parseISO(response.data.installments_ok[i].fecha_vencimiento), 'dd/MM/yyyy'),
                                   "cuota_valor": 
                                          (response.data.installments_ok[i].con_matricula === 0) ?     
                                          "$ "+formatNumber(response.data.installments_ok[i].cuota_valor) : "$ "+formatNumber(response.data.installments_ok[i].cuota_valor)+" (c/matrícula)"
                                   ,
                                   "cuota_int" : parseInt(response.data.installments_ok[i].cuota_valor),
                                   "apoderado": (response.data.installments_ok[i].rut_apoderado != "") ? Format(response.data.installments_ok[i].rut_apoderado)+' '+response.data.installments_ok[i].apoderado : '',
                                   "observacion" : response.data.installments_ok[i].observacion_cuota
                                 }
                              );
                          }
                         setInstallments(installmentsTmp);
                         setInstallmentsok(installmentsTmpOk);
                         setLoadingTable(false);
                         setConfirmarModalEdit(false)
                         toastr.success(response.data.message, 'OK');
                        
                    }
                })
            .catch((error) => {
                //setLoadingNew(false);
                if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                   toastr.error(error.message, 'Error')  
                  }
             })
   
            
      };
    

     const handleConfirmarCuota = () => {
         
        if(isAdmin && rutBuscar === ""){
            toastr.error('Debes ingresar un RUT para confirmar un pago.', 'Error');
            return;
        }
         
        const elementos = [...cuotaspagar];
        if (elementos && elementos.length > 0) {
            //setLoadingNew(true);
            let installmentsTmp = [];
            let installmentsTmpOk = [];
            
            let rutS = '';
        
            if(rutBuscar != ''){
                if(hasNumber(rutBuscar)) {
                    rutS = clean(rutBuscar);
                } else {
                    rutS = rutBuscar;
                }
            }

            //setLoadingTable(true);
            axios.post(`/installments/`+rutS, {data : elementos,fechapago, formapago})
            .then(
                (response) => {
                    if(!response.data || response.data.error){
                        toastr.error('Error interno al pagar cuota', 'Error');
                    } else { 
                       setInstallments([]); 
                       for(let i=0;i<response.data.installments.length;i++){
                            installmentsTmp.push(
                                {
                                 "id": response.data.installments[i].id,
                                 "cuota": response.data.installments[i].cuota,
                                 "rut":  (response.data.installments[i].rut_alumno != "") ? Format(response.data.installments[i].rut_alumno) : '',
                                 "alumno": response.data.installments[i].alumno,
                                 "curso": response.data.installments[i].curso,
                                 "fecha_cuota":  format(parseISO(response.data.installments[i].fecha_cuota), 'dd/MM/yyyy'),
                                 "fecha_vencimiento": format(parseISO(response.data.installments[i].fecha_vencimiento), 'dd/MM/yyyy'),
                                 "cuota_valor": 
                                        (response.data.installments[i].con_matricula === 0) ?     
                                        "$ "+formatNumber(response.data.installments[i].cuota_valor) : "$ "+formatNumber(response.data.installments[i].cuota_valor)+" (c/matrícula)"
                                 ,
                                 "cuota_int" : parseInt(response.data.installments[i].cuota_valor),
                                 "apoderado": (response.data.installments[i].rut_apoderado != "") ? Format(response.data.installments[i].rut_apoderado)+' '+response.data.installments[i].apoderado : '',
                                 "observacion" : response.data.installments[i].observacion_cuota
                               }
                            );
                        }
                        setInstallmentsok([]);
                        for(let i=0;i<response.data.installments_ok.length;i++){
                            installmentsTmpOk.push(
                                {
                                 "id": response.data.installments_ok[i].id,
                                 "cuota": response.data.installments_ok[i].cuota,
                                 "rut":  (response.data.installments_ok[i].rut_alumno != "") ? Format(response.data.installments_ok[i].rut_alumno) : '',
                                 "alumno": response.data.installments_ok[i].alumno,
                                 "curso": response.data.installments_ok[i].curso,
                                 "fecha_cuota":  format(parseISO(response.data.installments_ok[i].fecha_cuota), 'dd/MM/yyyy'),
                                 "updated_at": response.data.installments_ok[i].updated_at !== '0000-00-00' && response.data.installments_ok[i].updated_at !== '' ?  format(parseISO(response.data.installments_ok[i].updated_at), 'dd/MM/yyyy H:mm') : '',
                                 "fecha_vencimiento": format(parseISO(response.data.installments_ok[i].fecha_vencimiento), 'dd/MM/yyyy'),
                                 "cuota_valor": 
                                        (response.data.installments_ok[i].con_matricula === 0) ?     
                                        "$ "+formatNumber(response.data.installments_ok[i].cuota_valor) : "$ "+formatNumber(response.data.installments_ok[i].cuota_valor)+" (c/matrícula)"
                                 ,
                                 "cuota_int" : parseInt(response.data.installments_ok[i].cuota_valor),
                                 "apoderado": (response.data.installments_ok[i].rut_apoderado != "") ? Format(response.data.installments_ok[i].rut_apoderado)+' '+response.data.installments_ok[i].apoderado : '',
                                 "observacion" : response.data.installments_ok[i].observacion_cuota
                               }
                            );
                        }
                        setInstallments(installmentsTmp);
                        setInstallmentsok(installmentsTmpOk);
                        setLoadingNew(false);
                        setLoadingTable(false);
                        setConfirmarModal(false)
                        setPaginaInicio(0);  
                        setFechapago(new Date());
                        setFormapago('');
                        setCheckAll(false);
                        setCuotaspagar([]);
                        toastr.success(response.data.message, 'OK');
                      }
                })
            .catch((error) => {
                //setLoadingNew(false);
                if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                   toastr.error(error.message, 'Error')  
                  }
             })
        }
      };

    const onFocusRut = (rut, name) => 
    {
        if (rut != ''){
            rut = clean(rut);
            setRutBuscar(rut);
        }
    }
    
    const refreshTable = () => {
        
        if(isAdmin && rutBuscar === ""){
            toastr.error('Debes ingresar un RUT/Nombre para mostrar información.', 'Error');
            return;
        }
        
        let rutS = '';
        
        if(rutBuscar != ''){
            if(hasNumber(rutBuscar)) {
                rutS = clean(rutBuscar);
            } else {
                rutS = rutBuscar;
            }
        }

        setLoadingTable(true);
        let installmentsTmp = [];
        let installmentsTmpOk = [];
        axios.get('/installments/'+rutS, {}).then((response) => {
            if(!response.data){
            } else {
                
              if(isAdmin){
                  setCuotaspagar([]);
                  setCuotaspagarMonto([]);
                  setCheckAll(false);
                  setCheckAllok(false);
                  setCuotaspagarok([]);
                  setCuotaspagarMontook([]);
              }
                
               setInstallments([]); 
               for(let i=0;i<response.data.installments.length;i++){
                    installmentsTmp.push(
                        {
                         "id": response.data.installments[i].id,
                         "cuota": response.data.installments[i].cuota,
                         "rut":  (response.data.installments[i].rut_alumno != "") ? Format(response.data.installments[i].rut_alumno) : '',
                         "alumno": response.data.installments[i].alumno,
                         "curso": response.data.installments[i].curso,
                         "fecha_cuota":  format(parseISO(response.data.installments[i].fecha_cuota), 'dd/MM/yyyy'),
                         "fecha_vencimiento": format(parseISO(response.data.installments[i].fecha_vencimiento), 'dd/MM/yyyy'),
                         "cuota_valor": 
                                (response.data.installments[i].con_matricula === 0) ?     
                                "$ "+formatNumber(response.data.installments[i].cuota_valor) : "$ "+formatNumber(response.data.installments[i].cuota_valor)+" (c/matrícula)"
                         ,
                         "cuota_int" : parseInt(response.data.installments[i].cuota_valor),
                         "apoderado": (response.data.installments[i].rut_apoderado != "") ? Format(response.data.installments[i].rut_apoderado)+' '+response.data.installments[i].apoderado : '',
                         "observacion" : response.data.installments[i].observacion_cuota
                       }
                    );
                }
                setInstallmentsok([]);
                for(let i=0;i<response.data.installments_ok.length;i++){
                    installmentsTmpOk.push(
                        {
                         "id": response.data.installments_ok[i].id,
                         "cuota": response.data.installments_ok[i].cuota,
                         "rut":  (response.data.installments_ok[i].rut_alumno != "") ? Format(response.data.installments_ok[i].rut_alumno) : '',
                         "alumno": response.data.installments_ok[i].alumno,
                         "curso": response.data.installments_ok[i].curso,
                         "fecha_cuota":  format(parseISO(response.data.installments_ok[i].fecha_cuota), 'dd/MM/yyyy'),
                         "updated_at": response.data.installments_ok[i].updated_at !== '0000-00-00' && response.data.installments_ok[i].updated_at !== '' ?  format(parseISO(response.data.installments_ok[i].updated_at), 'dd/MM/yyyy H:mm') : '',
                         "fecha_vencimiento": format(parseISO(response.data.installments_ok[i].fecha_vencimiento), 'dd/MM/yyyy'),
                         "cuota_valor": 
                                (response.data.installments_ok[i].con_matricula === 0) ?     
                                "$ "+formatNumber(response.data.installments_ok[i].cuota_valor) : "$ "+formatNumber(response.data.installments_ok[i].cuota_valor)+" (c/matrícula)"
                         ,
                         "cuota_int" : parseInt(response.data.installments_ok[i].cuota_valor),
                         "apoderado": (response.data.installments_ok[i].rut_apoderado != "") ? Format(response.data.installments_ok[i].rut_apoderado)+' '+response.data.installments_ok[i].apoderado : '',
                         "observacion" : response.data.installments_ok[i].observacion_cuota
                       }
                    );
                }
               setInstallments(installmentsTmp);
               setInstallmentsok(installmentsTmpOk);
               setLoadingTable(false);
            }
          }, (error) => {
              setLoadingTable(false);
              if (error.response) {
                if(Array.isArray(error.response.data.message)){
                    for(let i=0;i<error.response.data.message.length;i++){
                        toastr.error(error.response.data.message[i], 'Error')
                    }
                } else {
                   toastr.error(error.response.data.message, 'Error')
                }
              } else if (error.request) {
                  toastr.error(error.request, 'Error')  
              } else {
                toastr.error(error.message, 'Error')  
              }
        });  
    }
    
    const onBlurRut = (rut, name) => 
    {
        if (rut != '') {
            if (rut.length > 3) {
                rut = Format(rut);
                setRutBuscar(rut);
            }
        } 
    };
    
    const formatNumber = (num) => {
        let numero = parseInt(num);
        numero = ("" + numero).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, function($1) { return $1 + "." });
        return numero;
    };
    
    const rowClickEdit = (cell) => 
    { 
        setMontoEditar(cell.row.original.cuota_int)
        setObservacionEditar(cell.row.original.observacion)
        setCuotaIdEditar(cell.row.original.id)
        setCuotaEditar(cell.row.original.cuota+' de '+cell.row.original.alumno+' ('+cell.row.original.curso+')');
        setConfirmarModalEdit(true);
        removeBodyCss();
    };
    
    const rowClick = (cell) => 
    {   
        let id = cell.row.cells[0].value;
        setLoadingTable(true);
        axios.get('/installments/'+id ,{}).then((response) => {
               if(!response.data || response.data.error){
                    toastr.error('Error interno al obtener información', 'Error');
                    setLoadingTable(false);
               } else {
                   let cuota = response.data[0];
                   let nombre = cuota.name;

                   setFormValues({
                        id: cuota.id,
                        nombre: nombre,
                        inicio: cuota.inicio,
                        fin: cuota.fin,
                        price: cuota.monthly_price,
                        m_price: cuota.inscription_price,
                        active: (cuota.active == 1) ? true : false
                    }); 
                    setLoadingTable(false);
                    tog_standardEdit();
                  }
            }, (error) => {
                 setLoadingTable(false);
                 toastr.error('Error interno al obtener información', 'Error')
           });  
        
    }
    
    const handleFilterChange = (columnId, value) => {
   
        setFilters({
          ...filters,
          [columnId]: value,
        });

        // Establece el filtro en la tabla
        setFilter(columnId, value);
      };

    
    const handleColumnSort = (column) => {
 
        if(column.id == "cuota"){
            return false;
        }
        let newSort = [];
        if(!column.isSorted && typeof column.isSortedDesc === 'undefined'){
             newSort = [{ id: column.id, desc: false }];
        }else if ( column.isSorted && column.isSortedDesc === false){
             newSort = newSort = [{ id: column.id, desc: true }];
        }else {
            newSort = newSort = [{ id: 'alumno', desc: false }];
        }
        setPaginaInicio(0);
        setSorting(newSort);
    };
    
    const handleCambioPagina = (valor) => {

        setCheckAll(false);
        setPaginaInicio(valor);
    };
    
    const handleSwitchChangeAll = (e,cell) => {

        if(!e){
            const elementos = document.getElementsByName('cuotasPagar');
            const elem = [...cuotaspagar];
            const cuotamonto = [...cuotaspagarmonto];

            elementos.forEach(cuota => {
                let valor = parseInt(cuota.value);
                const index = elem.indexOf(valor);
                if (index === -1) {// Si no está en el array, agregarlo
                    elem.push(valor);
                    let indice = installments.map(ins => ins.id).indexOf(valor);
                    cuotamonto.push(installments[indice].cuota_int);
                  }
            });
            setCheckAll(true);
            setCuotaspagar(elem);
            setCuotaspagarMonto(cuotamonto);
        } else {
            
            const elementos = document.getElementsByName('cuotasPagar');
            const elem = [...cuotaspagar];
            const cuotamonto = [...cuotaspagarmonto];
  
            elementos.forEach(cuota => {
                let valor = parseInt(cuota.value);
                const index = elem.indexOf(valor);
                if (index !== -1) {// Si ya está en el array, eliminarlo
                    elem.splice(index, 1);
                    cuotamonto.splice(index, 1);
                }
            });
            
            setCuotaspagar(elem);
            setCuotaspagarMonto(cuotamonto);
            setCheckAll(false);
        }
    };
    
    const handleSwitchChangeAllok = (e,cell) => {
        
        if(!e){
            
            const elementos = document.getElementsByName('cuotasPagarok');
            const elem = [...cuotaspagarok];
            const cuotamontook = [...cuotaspagarmontook];

            elementos.forEach(cuota => {
                
                let valor = parseInt(cuota.value);
                const index = elem.indexOf(valor);
                if (index === -1) {// Si no está en el array, agregarlo
                    elem.push(valor);
                    let indice = installmentsok.map(ins => ins.id).indexOf(valor);
                    cuotamontook.push(installmentsok[indice].cuota_int);
                  }
            });
            setCheckAllok(true);
            setCuotaspagarok(elem);
            setCuotaspagarMontook(cuotamontook);
        } else {
            const elementos = document.getElementsByName('cuotasPagarok');
            const elem = [...cuotaspagarok];
            const cuotamontook = [...cuotaspagarmontook];
  
            elementos.forEach(cuota => {
                let valor = parseInt(cuota.value);
                const index = elem.indexOf(valor);
                if (index !== -1) {// Si ya está en el array, eliminarlo
                    elem.splice(index, 1);
                    cuotamontook.splice(index, 1);
                }
            });
            
            setCuotaspagarok(elem);
            setCuotaspagarMontook(cuotamontook);
            setCheckAllok(false);
        }

    };
    
    const handleSwitchChange = (value,row) => {
        
        let valor = parseInt(value);
        // Verificar si el valor ya está en el array
        const index = cuotaspagar.indexOf(valor);

        if (index === -1) {
          // Si no está en el array, agregarlo
          setCuotaspagar([...cuotaspagar, valor]);
          setCuotaspagarMonto([...cuotaspagarmonto, row.original.cuota_int]);
        } else {
          // Si ya está en el array, eliminarlo
          const newCuotasPagar = [...cuotaspagar];
          newCuotasPagar.splice(index, 1);
          setCuotaspagar(newCuotasPagar);
          
          const newCuotaPagarMonto = [...cuotaspagarmonto];
          newCuotaPagarMonto.splice(index, 1);
          setCuotaspagarMonto(newCuotaPagarMonto);

        }
        setCheckAll(false);
      };
      
      const handleSwitchChangeok = (value,row) => {
        let valor = parseInt(value);
        // Verificar si el valor ya está en el array
        const index = cuotaspagarok.indexOf(valor);

        if (index === -1) {
          // Si no está en el array, agregarlo
          setCuotaspagarok([...cuotaspagarok, valor]);
          setCuotaspagarMontook([...cuotaspagarmontook, row.original.cuota_int]);
        } else {
          // Si ya está en el array, eliminarlo
          const newCuotasPagar = [...cuotaspagarok];
          newCuotasPagar.splice(index, 1);
          setCuotaspagarok(newCuotasPagar);
          
          const newCuotaPagarMonto = [...cuotaspagarmontook];
          newCuotaPagarMonto.splice(index, 1);
          setCuotaspagarMontook(newCuotaPagarMonto);

        }
        setCheckAllok(false);
      };


    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
      };
    
      useEffect(() => {
          
        if(jsonString.indexOf('administrador') === -1 && jsonString.indexOf('apoderado') === -1){
            props.router.navigate('/pages-404');
            return;
        }
        
        const searchParams = new URLSearchParams(window.location.search);
        const paramsObject = {};
        for (const [key, value] of searchParams.entries()) {
          paramsObject[key] = value;
        }

        if(jsonString.indexOf('administrador') === -1){
  
            let installmentsTmp = [];
            let installmentsTmpOk = [];
            axios.get('/installments', {}).then((response) => {
                   if(!response.data || response.data.error){
                       toastr.error('Error interno al obtener información', 'Error')
                   } else {
                      for(let i=0;i<response.data.installments.length;i++){
                           installmentsTmp.push(
                               {
                                "id": response.data.installments[i].id,
                                "cuota": response.data.installments[i].cuota,
                                "rut":  (response.data.installments[i].rut_alumno != "") ? Format(response.data.installments[i].rut_alumno) : '',
                                "alumno": response.data.installments[i].alumno,
                                "curso": response.data.installments[i].curso,
                                "fecha_cuota":  format(parseISO(response.data.installments[i].fecha_cuota), 'dd/MM/yyyy'),
                                "fecha_vencimiento": format(parseISO(response.data.installments[i].fecha_vencimiento), 'dd/MM/yyyy'),
                                "cuota_valor": 
                                    (response.data.installments[i].con_matricula === 0) ?     
                                    "$ "+formatNumber(response.data.installments[i].cuota_valor) : "$ "+formatNumber(response.data.installments[i].cuota_valor)+" (c/matrícula)"
                                ,
                                "cuota_int" : parseInt(response.data.installments[i].cuota_valor),
                                "apoderado": (response.data.installments[i].rut_apoderado != "") ? Format(response.data.installments[i].rut_apoderado)+' '+response.data.installments[i].apoderado : '',
                                "observacion" : response.data.installments[i].observacion_cuota
                              }
                           );
                       }
                       for(let i=0;i<response.data.installments_ok.length;i++){
                           installmentsTmpOk.push(
                               {
                                "id": response.data.installments_ok[i].id,
                                "cuota": response.data.installments_ok[i].cuota,
                                "rut":  (response.data.installments_ok[i].rut_alumno != "") ? Format(response.data.installments_ok[i].rut_alumno) : '',
                                "alumno": response.data.installments_ok[i].alumno,
                                "curso": response.data.installments_ok[i].curso,
                                "fecha_cuota":  format(parseISO(response.data.installments_ok[i].fecha_cuota), 'dd/MM/yyyy'),
                                "updated_at": response.data.installments_ok[i].updated_at !== '0000-00-00' && response.data.installments_ok[i].updated_at !== '' ?  format(parseISO(response.data.installments_ok[i].updated_at), 'dd/MM/yyyy H:mm') : '',
                                "fecha_vencimiento": format(parseISO(response.data.installments_ok[i].fecha_vencimiento), 'dd/MM/yyyy'),
                                "cuota_valor": 
                                    (response.data.installments_ok[i].con_matricula === 0) ?     
                                    "$ "+formatNumber(response.data.installments_ok[i].cuota_valor) : "$ "+formatNumber(response.data.installments_ok[i].cuota_valor)+" (c/matrícula)"
                                ,
                                "cuota_int" : parseInt(response.data.installments_ok[i].cuota_valor),
                                "apoderado": (response.data.installments_ok[i].rut_apoderado != "") ? Format(response.data.installments_ok[i].rut_apoderado)+' '+response.data.installments_ok[i].apoderado : '',
                                "observacion" : response.data.installments_ok[i].observacion_cuota
                              }
                           );
                       }
                      setIsAdmin(false);
                      setInstallments(installmentsTmp);
                      setInstallmentsok(installmentsTmpOk);
                      setLoadingTable(false);

                     if(paramsObject.TBK_TOKEN && paramsObject.TBK_TOKEN != ""){
                         setmodal_cancelado(true);
                     }
                     
                     const pagotbk = document.getElementById('root').getAttribute('data-pagotbk');
                     const mensajeError = document.getElementById('root').getAttribute('data-mensajeError');
                     const mensajeOk = document.getElementById('root').getAttribute('data-mensajeOk');
                     
                     if(pagotbk == 'pago_ok'){
                         setmodal_pagado_ok(true);
                         setmensajeOkTbk(mensajeOk);
                     } else if (pagotbk == 'error_validacion_tbk' || pagotbk == 'error_validacion_logica'){
                         setmodal_pagado_error(true);
                         setmensajeErrorTbk(mensajeError);
                     }
               
                   }
                 }, (error) => {
                      toastr.error('Error interno al obtener información', 'Error');
                      setIsAdmin(false);
                      setInstallments([]);
                      setInstallmentsok([]);
                      setLoadingTable(false); 
               });  
            }else{
                setIsAdmin(true);
                setInstallments([]);
                setInstallmentsok([]);
                setLoadingTable(false);
            }
     }, []);
     
    const columns = useMemo(
        () => [
            /*{
                Header: 'ID',
                accessor: 'id',
                isVisible: false
            },*/
            {
                Header: (cellProps) => {
          
                    return (<React.Fragment>
                            <div># Cuota</div>
                            <div className="form-check form-switch text-center pt-3">
                            {viendoPendientes &&
                            <Input
                              type="checkbox"
                              onChange={(e) => handleSwitchChangeAll(checkAll,cellProps)}
                              onClick={(e) => handleSwitchChangeAll(checkAll,cellProps)}
                              className="form-check-input"
                              invalid={false}
                              checked={checkAll}
                              name=""  
                            />
                            }
                            {isAdmin && !viendoPendientes &&
                            <Input
                              type="checkbox"
                              onChange={(e) => handleSwitchChangeAllok(checkAllok,cellProps)}
                              onClick={(e) => handleSwitchChangeAllok(checkAllok,cellProps)}
                              className="form-check-input"
                              checked={checkAllok}
                              name=""  
                            />
                            }
                            </div>
                        </React.Fragment>)
                },
                accessor: 'cuota',
                disableFilters: true,
                disableSortBy: true,
                filterable: false,
                disableClick: true,
                Cell: (cellProps) => {
                    
                   const contador = cellProps.row.original.id; // Aquí defines tu contador o ID variable
                   const checkboxId = `invalidCheck-${contador}`; // Concatenas el contador o ID en el ID del checkbox
                   const checkboxIdok = `invalidCheckok-${contador}`;
                  return (
                   <div className="form-check form-switch">
                           
                            {viendoPendientes &&
                             <React.Fragment>        
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              onClick={(e) => handleSwitchChange(e.target.value,cellProps.row)}
                              onChange={(e) => handleSwitchChange(e.target.value,cellProps.row)}
                              checked={cuotaspagar.includes(cellProps.row.original.id)}
                              name="cuotasPagar"
                              id={checkboxId}
                              value={cellProps.row.original.id}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor={checkboxId}
                            >
                              {" "}
                              {cellProps.row.original.cuota}
                            </Label>
                             </React.Fragment>
                            }
                            {!viendoPendientes &&
                              <React.Fragment>  
                              {isAdmin &&  
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              onClick={(e) => handleSwitchChangeok(e.target.value,cellProps.row)}
                              onChange={(e) => handleSwitchChangeok(e.target.value,cellProps.row)}
                              checked={cuotaspagarok.includes(cellProps.row.original.id)}
                              name="cuotasPagarok"
                              id={checkboxIdok}
                              value={cellProps.row.original.id}
                            />
                            }
                            <Label
                              className="form-check-label"
                              htmlFor={checkboxIdok}
                            >
                              {" "}
                              {cellProps.row.original.cuota}
                            </Label>
                             </React.Fragment>        
                            }
                            
                    </div>       
                   );
                }
            },
            {
                Header: 'RUT Alumno',
                accessor: 'rut'
            },
            {
                Header: 'Alumno',
                accessor: 'alumno'
            },
            {
                Header: 'Curso',
                accessor: 'curso'
            },
            /*{
                Header: 'Emisión',
                accessor: 'fecha_cuota',
                meta: {
                    align: 'right'
                  }
            },*/
            {
                Header: 'Vencimiento',
                accessor: 'fecha_vencimiento',
                meta: {
                    align: 'right'
                  }
            },
            {
                Header: 'Valor',
                accessor: 'cuota_valor',
                meta: {
                    align: 'right'
                  },
                  Cell: ({ row }) => {
                        const { observacion, cuota_valor,id } = row.original;
                        // Lógica condicional para determinar el valor a mostrar
                        return observacion === '' ? cuota_valor : <div id={'obs_'+id}><span >{cuota_valor} *</span><UncontrolledTooltip target={'obs_'+id} placement="left" >{observacion}</UncontrolledTooltip></div>;
                    }
            },
            {
                Header: 'Apoderado',
                accessor: 'apoderado'
            },
            
            {
                Header: 'Acciones',
                accessor: 'action',
                disableFilters: true,
                filterable: false,
                Cell: (cellProps) => {
                  return (
                   <div className="d-flex text-center">
                        <div
                        style={{cursor:'pointer'}} 
                        className="text-success text-center"
                        onClick={() => { rowClickEdit(cellProps); }}
                      >
                        <i style={{cursor:'pointer'}}  className="mdi mdi-pencil font-size-18" id={'edita_'+cellProps.row.original.id} />
                        <UncontrolledTooltip placement="top" target={'edita_'+cellProps.row.original.id}>
                          Editar
                        </UncontrolledTooltip>
                      </div>
                    </div>
                        
                  );
                }
              }
        ],
        [cuotaspagar, cuotaspagarok, installments, installmentsok, checkAll, viendoPendientes]
    );
    
    const columnsPagadas = useMemo(
        () => [
            /*{
                Header: 'ID',
                accessor: 'id',
                isVisible: false
            },*/
            {
                Header: (cellProps) => {
          
                    return (<React.Fragment>
                            <div># Cuota</div>
                            <div className="form-check form-switch text-center pt-3">
                            {viendoPendientes &&
                            <Input
                              type="checkbox"
                              onChange={(e) => handleSwitchChangeAll(checkAll,cellProps)}
                              onClick={(e) => handleSwitchChangeAll(checkAll,cellProps)}
                              className="form-check-input"
                              invalid={false}
                              checked={checkAll}
                              name=""  
                            />
                            }
                            {isAdmin && !viendoPendientes &&
                            <Input
                              type="checkbox"
                              onChange={(e) => handleSwitchChangeAllok(checkAllok,cellProps)}
                              onClick={(e) => handleSwitchChangeAllok(checkAllok,cellProps)}
                              className="form-check-input"
                              checked={checkAllok}
                              name=""  
                            />
                            }
                            </div>
                        </React.Fragment>)
                },
                accessor: 'cuota',
                disableFilters: true,
                disableSortBy: true,
                filterable: false,
                disableClick: true,
                Cell: (cellProps) => {
                    
                   const contador = cellProps.row.original.id; // Aquí defines tu contador o ID variable
                   const checkboxId = `invalidCheck-${contador}`; // Concatenas el contador o ID en el ID del checkbox
                   const checkboxIdok = `invalidCheckok-${contador}`;
                  return (
                   <div className="form-check form-switch">
                           
                            {viendoPendientes &&
                             <React.Fragment>        
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              onClick={(e) => handleSwitchChange(e.target.value,cellProps.row)}
                              onChange={(e) => handleSwitchChange(e.target.value,cellProps.row)}
                              checked={cuotaspagar.includes(cellProps.row.original.id)}
                              name="cuotasPagar"
                              id={checkboxId}
                              value={cellProps.row.original.id}
                            />
                            <Label
                              className="form-check-label"
                              htmlFor={checkboxId}
                            >
                              {" "}
                              {cellProps.row.original.cuota}
                            </Label>
                             </React.Fragment>
                            }
                            {!viendoPendientes &&
                              <React.Fragment>  
                              {isAdmin &&  
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              onClick={(e) => handleSwitchChangeok(e.target.value,cellProps.row)}
                              onChange={(e) => handleSwitchChangeok(e.target.value,cellProps.row)}
                              checked={cuotaspagarok.includes(cellProps.row.original.id)}
                              name="cuotasPagarok"
                              id={checkboxIdok}
                              value={cellProps.row.original.id}
                            />
                            }
                            <Label
                              className="form-check-label"
                              htmlFor={checkboxIdok}
                            >
                              {" "}
                              {cellProps.row.original.cuota}
                            </Label>
                             </React.Fragment>        
                            }
                            
                    </div>       
                   );
                }
            },
            {
                Header: 'RUT Alumno',
                accessor: 'rut'
            },
            {
                Header: 'Alumno',
                accessor: 'alumno'
            },
            {
                Header: 'Curso',
                accessor: 'curso'
            },
            /*{
                Header: 'Emisión',
                accessor: 'fecha_cuota',
                meta: {
                    align: 'right'
                  }
            },*/
            {
                Header: 'Vencimiento',
                accessor: 'fecha_vencimiento',
                meta: {
                    align: 'right'
                  }
            },
            {
                Header: 'Valor',
                accessor: 'cuota_valor',
                meta: {
                    align: 'right'
                  },
                  Cell: ({ row }) => {
                        const { observacion, cuota_valor,id } = row.original;
                        // Lógica condicional para determinar el valor a mostrar
                        return observacion === '' ? cuota_valor : <div id={'obs_'+id}><span >{cuota_valor} *</span><UncontrolledTooltip target={'obs_'+id} placement="left" >{observacion}</UncontrolledTooltip></div>;
                    }
            },
            {
                Header: 'Fecha Pago',
                accessor: 'updated_at'
            },
            {
                Header: 'Apoderado',
                accessor: 'apoderado'
            },
            
            {
                Header: 'Acciones',
                accessor: 'action',
                disableFilters: true,
                filterable: false,
                Cell: (cellProps) => {
                  return (
                   <div className="form">
                    <UncontrolledTooltip placement="top" target={"descarga"+cellProps.row.original.id}>
                          Descargar Boleta
                        </UncontrolledTooltip>
                      <img
                        src={pdf}
                        onClick={(e) => {
                        descargaBoleta(e,cellProps.row.original.id);
                      }}
                        id={"descarga"+cellProps.row.original.id}
                        alt="Descargar Boleta"
                        className=" img-thumbnail"
                      />
                 </div>       
                        
                  );
                }
              },
        ],
        [cuotaspagar, cuotaspagarok, installments, installmentsok, checkAll, viendoPendientes]
    );
    
    const handleFechaPagoChange = fecha => {
        setFechapago(fecha);
      };
    const handleFormaPagoChange = e => {
        setFormapago(e.target.value);
    };
    const handleMontoPagoChange = e => {
        setMontoEditar(e.target.value);
    };
    const handleObservacionPagoChange = e => {
        setObservacionEditar(e.target.value);
    };
    
    const tog_cancelado = () => {
        setmodal_cancelado(!modal_cancelado);
        removeBodyCss();
    }
    
    const tog_pagado_ok = () => {
        setmodal_pagado_ok(!modal_pagado_ok);
        removeBodyCss();
    }
    
    const tog_pagado_error = () => {
        setmodal_pagado_error(!modal_pagado_error);
        removeBodyCss();
    }
    
    const hasNumber = (valor) => { 
        let has = /\d/;
        return has.test(valor); 
    };

    
    const onCheckSearch = (rut, name) => 
    {
        if (rut != '') {
            if (rut.length > 3 && hasNumber(rut)) {
                rut = Format(rut);
                setRutBuscar(rut);
            } else {
                setRutBuscar(rut);
            }
        } 
    };

    document.title = "Cuotas | Listado";
    return (
        
        <div className="page-content">
            <Modal
                isOpen={modal_cancelado}
                toggle={() => {
                  tog_cancelado();
                }}
                backdrop={'static'}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Pago Anulado</h5>
                  <button
                    type="button"
                    onClick={() => {
                      setmodal_cancelado(false);
                    }}
                    className="btn-close position-absolute end-0 top-0 m-3"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                   
                  </button>
                </div>
                <div className="modal-body">
                  <p>
                    Has cancelado el pago, o estuviste mucho tiempo de inactividad y transbank te ha
                    redirigido de vuelta al sitio.
                  </p>
                  <p>Puedes intentar pagar nuevamente si así lo deseas en cualquier momento.</p>
                </div>
                <div className="modal-footer">
                <button type="button" onClick={() => {
                  setmodal_cancelado(false);
                }} className="btn btn-primary">Aceptar</button>
                </div>
              </Modal>
              <Modal
                isOpen={modal_pagado_ok}
                toggle={() => {
                  tog_pagado_ok();
                }}
                backdrop={'static'}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Pago Aprobado</h5>
                  <button
                    type="button"
                    onClick={() => {
                      setmodal_pagado_ok(false);
                    }}
                    className="btn-close position-absolute end-0 top-0 m-3"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                   
                  </button>
                </div>
                <div className="modal-body">
                  <p dangerouslySetInnerHTML={{ __html: mensajeOkTbk }} />
                </div>
                <div className="modal-footer">
                <button type="button" onClick={() => {
                  setmodal_pagado_ok(false);
                }} className="btn btn-primary">Aceptar</button>
                </div>
              </Modal>
              <Modal
                isOpen={modal_pagado_error}
                toggle={() => {
                  tog_pagado_error();
                }}
                backdrop={'static'}
                centered
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0">Ha ocurrido un error con su pago</h5>
                  <button
                    type="button"
                    onClick={() => {
                      setmodal_pagado_error(false);
                    }}
                    className="btn-close position-absolute end-0 top-0 m-3"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                   
                  </button>
                </div>
                <div className="modal-body pb-0">
                  <p dangerouslySetInnerHTML={{ __html: mensajeErrorTbk }} />
                  <p>  
                    <b>Las posibles causas de este rechazo son:</b> 
                    <br />
                    <br />- Error en el ingreso de los datos de su tarjeta de Crédito o Débito (fecha y/o código de seguridad). 
                    <br /><br />- Su tarjeta de Crédito o Débito no cuenta con saldo suficiente.
                    <br /><br />- Tarjeta aun no habilitada en el sistema financiero. 
                 </p>
                </div>
                <div className="modal-footer">
                <button type="button" onClick={() => {
                  setmodal_pagado_error(false);
                }} className="btn btn-primary">Aceptar</button>
                </div>
              </Modal>
           <ConfirmarModal
            show={confirmarModal}
            itemName={itemName}
            fechapago={fechapago}
            formapago={formapago}
            onChangeFechaPago={handleFechaPagoChange}
            onChangeFormaPago={handleFormaPagoChange}
            onConfirmarClick={handleConfirmarCuota}
            onCloseClick={() => setConfirmarModal(false)}
          />
          <ConfirmarModalEdit
            show={confirmarEditModal}
            cuota_editar={cuota_editar}
            monto_editar={monto_editar}
            observacion_editar={observacion_editar}
            onChangeMontoPago={handleMontoPagoChange}
            onChangeObservacionPago={handleObservacionPagoChange}
            onConfirmarClick={handleConfirmarEditCuota}
            onCloseClick={() => setConfirmarModalEdit(false)}
          />
          <DeleteCuotaModal
            show={deleteCuotaModal}
            itemName={itemName}
            onDeleteClick={handleDeleteCuota}
            onCloseClick={() => setDeleteCuotaModal(false)}
          />
            <Container fluid={true}>
                <Breadcrumbs title="Cuotas" breadcrumbItem="Listado" />
                <Row>
            <Col className="col-12">
              <Card>
                <CardBody >
                  <CardTitle className="h4">Listado de Cuotas</CardTitle>
                  {/*installments.length <= 0 ||*/ loadingTable  &&  
                  <div style={{ height: '65vh' }}  className="textoCentrado spinner-content-new" >
                  <Spinner className="ms-2" color="primary" />
                  </div>
                  }
                  { !loadingTable  &&
                  <div style={{ display: !loadingTable ? '' : 'none' }} className="table-hover">
                    <div className="row">
                    <div className="col-md-6 col-sm-12 text-sm-start">
                    <div className="row row-cols-lg-auto g-3 align-items-center">
                    {viendoPendientes &&
                    <Button
                    onClick={e => {
                       pagarModal(e);
                     }}
                      disabled={cuotaspagar.length <= 0}  
                      color="warning"
                      style={{ marginLeft: '8px' }}
                      className="btn col-6 btn-warning waves-effect waves-light mb-2"
                    >
                      Pagar ({cuotaspagar.length})
                    </Button>
                    }
                    
                    {isAdmin && !viendoPendientes &&
                    <Button
                    onClick={e => {
                       eliminarModal(e);
                     }}
                      disabled={cuotaspagarok.length <= 0}  
                      color="warning"
                      style={{ marginLeft: '8px' }}
                      className="btn col-6 btn-warning waves-effect waves-light mb-2"
                    >
                      Eliminar Pago ({cuotaspagarok.length})
                    </Button>
                    }
                    {isAdmin && 
                       <div className="form-floating mb-2 col-6">
                        <input 
                        name="rutBuscar" 
                        type="text"
                        onFocus={(e) => onCheckSearch(e.target.value, e.target.name)}
                        onBlur={(e) => onCheckSearch(e.target.value, e.target.name)}
                        onChange={(e) => setRutBuscar(e.target.value)} 
                        value={rutBuscar} 
                        className="form-control" 
                        id="rutBuscar" 
                        placeholder="Ingrese RUT" />
                        <label htmlFor="rutBuscar">Búsqueda</label>
                     </div>  
                    }
                    </div>
                    </div>
                    <div className="col-md-6 col-sm-12 text-sm-end">
                    <Button
                    onClick={e => {
                       cambiarVista();
                     }}
                      color={viendoPendientes ? 'danger' : 'success'}
                      className="btn waves-effect waves-light ml-2 mb-2 floar-rigth rigth pull-rigth"
                    >
                      Viendo {viendoPendientes ? 'Pendientes' : 'Pagadas'} ({ viendoPendientes ? installments.length : installmentsok.length})
                    </Button>
                    </div>
                    </div>
                    <TableContainer
                      columns={viendoPendientes ? columns : columnsPagadas }
                      data={viendoPendientes ? installments : installmentsok }
                      handleRefreshClick={() => { refreshTable(); }}
                      handleUserClick={() => { tog_standard(); }}
                      isAddUserList={true}
                      customPageSizeOptions={true}
                      isGlobalFilter={false}
                      isAddOptions={false}
                      isRefresh={true}
                      sortParams={sorting}
                      handleFilterChange={(e,val) => { handleFilterChange(e,val); }}
                      handleColumnSort={(e) => { handleColumnSort(e); }}
                      customPageSize={10}
                      className="custom-header-css"
                      rowClick={false}
                      handleCambioPagina={(e)=> { handleCambioPagina(e); }}
                      paginaInicio={paginaInicio}
                      handleRowClick={(e) => { rowClick(e); }}
                      />
                  </div>
                 }
                </CardBody>
                </Card>
                </Col >
                 </Row>
            </Container>
        </div>
    );
}
CuotasList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};


export default withRouter(CuotasList);