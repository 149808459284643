import PropTypes from 'prop-types'
import React, {  useState } from "react"
import { Modal, ModalBody, Col,
  Row,
  Card,
  CardBody, Label , UncontrolledAlert, Button, Alert } from "reactstrap";
  //import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"

import { format, parseISO  } from 'date-fns';

const ConfirmarEmailModal = ({ show, onConfirmarEmailClick, onCloseClick, itemName, envioOK , cargandoMail, notiAnteriores}) => {

  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <Button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></Button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <i className="mdi mdi-mail font-size-18"></i>
            </div>
          </div>
          {!envioOK  ? (
          <React.Fragment>       
          <p className="font-size-16 mb-3">¿Estás seguro de enviar un Recordatorio de Pago al apoderado de {itemName}?</p>
          <div className="hstack gap-2 justify-content-center mb-0">
            <Button type="button" disabled={cargandoMail} className="btn btn-danger" onClick={onConfirmarEmailClick}>Confirmar</Button>
            <Button type="button" disabled={cargandoMail} className="btn btn-secondary" onClick={onCloseClick}>Cerrar</Button>
          </div>
            { (notiAnteriores && notiAnteriores.length > 0) &&
            <Alert className="mt-3" color="info" role="alert">
             <div>
                 <p><b>Notificaciones enviadas anteriormente</b></p>
               {notiAnteriores.map((item, key) => (
                <div className="mt-2" style={{ textAlign: "left" }} key={key} >
                  <b>*</b> {item.course_name} ({item.contador} enviados), último {item.creacion}
                </div>
              ))}
            </div>  
            </Alert>      
            }

           </React.Fragment>
           ) : ( <Alert color="success" role="alert">
                Recordatorio de Pago enviado al apoderado de {itemName}
              </Alert> ) 
            }
        </ModalBody>
      </div>
    </Modal>
  )
}

ConfirmarEmailModal.propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmarEmailClick: PropTypes.func,
  show: PropTypes.any,
  envioOK: PropTypes.any,
  cargandoMail: PropTypes.any,
  notiAnteriores : PropTypes.any
}

export default ConfirmarEmailModal
