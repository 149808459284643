import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import {validate as Validate, clean, format as Format} from 'rut.js'
import toastr from "toastr";

//redux
import { useSelector, useDispatch } from "react-redux";
import withRouter from "components/Common/withRouter";

//Import Breadcrumb
import Breadcrumb from "components/Common/Breadcrumb";

import avatar from "../../assets/images/users/no-photo.png";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

const UserProfile = () => {

  //meta title
  document.title = "Perfil";

  const dispatch = useDispatch();

  const [email, setemail] = useState("");
  const [name, setname] = useState("");
  const [rut, setrut] = useState("");
  const [idx, setidx] = useState(1);
  const [rol, setrol] = useState("");
  const [loading,setLoading] = useState(false);
  const [validacion,setValidation] = useState(false);
  const [mostrarOk,setMostrarOk] = useState(false);

  const { error, success } = useSelector(state => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))[0];
    
      if (process.env.MIX_REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setidx(obj.uid);
        setrut(Format(obj.rut));
      } else if (
        process.env.MIX_REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.MIX_REACT_APP_DEFAULTAUTH === "jwt" ||
        process.env.MIX_REACT_APP_DEFAULTAUTH === "laravel"
      ) {

        setname(obj.name);
        setemail(obj.email);
        setidx(obj.id);
        setrut(Format(obj.rut));
        setrol(JSON.parse(obj.rol).join(", "));
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
      
    enableReinitialize: true,

    initialValues: {
      password1: '',
      password2: ''
    },
    validationSchema: Yup.object({
      password1: Yup.string().required("Por favor ingresa contraseña"),
      password2: Yup.string().required("Por favor repita contraseña").oneOf([Yup.ref("password1")], "Contraseñas no son iguales"),
    }),
    onSubmit: (values) => {
     setLoading(true);
     let password1 = values.password1;
     let password2 = values.password2;

     axios.put(`/users/password`,{password1, password2})
        .then(
            (res) => {
                setLoading(false);
                if(!res.data){
                    toastr.error('Error al modificar contraseña, intente nuevamente', 'Error');
                    //validation.setFieldValue("password1", "");
                    //validation.setFieldValue("password2", "");
                    //this.setState({  loading : false, password1: '', password2:'', validacion:true, mostrar:false });
                } else {
                    
                    //validation.setFieldValue("password1", "");
                    //validation.setFieldValue("password2", "");
                    validation.resetForm();
                    toastr.success(res.data.message, 'OK');
                    
                    //this.setState({ email: email, token: token, mostrar : true, loading : false , validacion:true, mostrarOk:true});
                }
            })
        .catch((error) => {
             setLoading(false);
            if (error.response) {
                toastr.error(error.response.data.message, 'Error');
              } else if (error.request) {
                toastr.error(error.request, 'Error');
              } else {
                toastr.error(error.message, 'Error');
              }
         });
     
    }
  });


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumb title="Perfil"  />

          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center ms-3">
                      <div className="">
                        <h5>{name}</h5>
                        <p className="mb-0">Id no: #{idx}</p>
                        <p className="mb-0">{rut}</p>
                        <p className="mb-0">{email}</p>
                        <p className="mb-0">{rol}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-2">Cambiar Contraseña</h4>

          <Card>
            <CardBody>
              <Form
                 className="form-horizontal"
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <div className="mb-3">
                        <Label className="form-label">Contraseña</Label>
                        <Input
                          name="password1"
                          disabled={loading}
                          className="form-control"
                          placeholder="Ingresa contraseña"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={(e) => {
                            validation.handleBlur;
                          }}
                          value={validation.values.password1 || ""}
                          invalid={
                            validation.touched.password1 && validation.errors.password1 ? true : false
                          }
                        />
                        {validation.touched.password1 && validation.errors.password1 ? (
                          <FormFeedback type="invalid">{validation.errors.password1}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Repita contraseña</Label>
                        <Input
                          name="password2"
                          disabled={loading}
                          className="form-control"
                          placeholder="Repita contraseña"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={(e) => {
                            validation.handleBlur;
                          }}
                          value={validation.values.password2 || ""}
                          invalid={
                            validation.touched.password2 && validation.errors.password2 ? true : false
                          }
                        />
                        {validation.touched.password2 && validation.errors.password2 ? (
                          <FormFeedback type="invalid">{validation.errors.password2}</FormFeedback>
                        ) : null}
                      </div>
                <div className="text-center mt-4">
                  <Button type="submit" color="danger">
                    Modificar
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
