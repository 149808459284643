// src/components/filter.
import React, { useMemo, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withRouter from "components/Common/withRouter";

//import components
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableContainer from 'components/Common/TableContainer';

import Switch from "react-switch";
import Select from "react-select";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { SketchPicker } from "react-color";
import ColorPicker from "@vtaits/react-color-picker";

import DeleteModal from "components/Common/DeleteModal";
//Import Flatepicker
//import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"

import {validate as Validate, clean, format as Format} from 'rut.js'
import { format, parseISO  } from 'date-fns';

import {
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Tooltip,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Spinner,
  Badge,
  Modal,
  UncontrolledPopover,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Label,
  Form,
  Input,
  InputGroup,
  UncontrolledTooltip
} from "reactstrap";

const UsuariosList = props => {
    
  const clearToast = () => {
    toastr.clear();
  }

    const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Si
      </div>
    );
  };
  
   const InactiveSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  
  const ActiveSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Si
      </div>
    );
  };
    
    const [modal_standard, setmodal_standard] = useState(false);
    const [usuarios, setUsuarios] = useState([]);
    const [loadingTable, setLoadingTable] = useState(true);
    const [loadingNew, setLoadingNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [sorting, setSorting] = useState([{ id: 'name', desc: false }]);
    const [paginaInicio, setPaginaInicio] = useState(0);
    const [filters, setFilters] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);
    const [contact, setContact] = useState();
    const [itemName, setItemName] = useState('');
    
    const jsonString = (localStorage.getItem("authUser") && localStorage.getItem("authUser").length > 0) ? JSON.parse(JSON.parse(localStorage.getItem("authUser"))[0].rol) : [];

    /*datos usuario*/
    const [formValues, setFormValues] = useState({
        id: 0,
        rut: '',
        nombre: '',
        apellidos: '',
        email: '',
        celular: '',
        nacimiento: '',
        direccion: '',
        tallaSelect: 0,
        rutApoderado: '',
        beca: false,
        checkAlumno: false,
        checkApoderado: false,
        checkEncargado: false,
        checkProfesor: false,
        active: true
      });
    
    const handleDateChange = (selectedDates, field) => {
        setFormValues({
          ...formValues,
          [field]: selectedDates,
        });
      };

      const handleCheckChange = (event) => {
       const { name } = event.target; 
        setFormValues({
          ...formValues,
          [name]: !event.target.checked
        });
      };
      
      const handleSwitchChange = (event,field) => {
        setFormValues({
          ...formValues,
          [field]: event,
        });
      };
    
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
          ...formValues,
          [name]: value,
        });
    };
    
    const tog_standardEdit = () => {
        setEdit(true);
        setmodal_standard(!modal_standard);
        removeBodyCss();
    };

    const tog_standard = () => {
        
        setFormValues({
            id: 0,
            rut: '',
            nombre: '',
            apellidos: '',
            email: '',
            celular: '',
            nacimiento: '',
            direccion: '',
            tallaSelect: 0,
            rutApoderado: '',
            beca: false,
            checkAlumno: false,
            checkApoderado: false,
            checkEncargado: false,
            checkProfesor: false,
            active: true
        });
        
        setEdit(false);
        setmodal_standard(!modal_standard);
        removeBodyCss();
    };
    
     const onClickDelete = users => {
        setItemName('usuario '+users.name)
        setContact(users);
        setDeleteModal(true);
    };

     const handleDeleteUser = () => {

        if (contact && contact.id) {
            let usuariosTmp = [];
            axios.delete(`/users`, {data : contact})
            .then(
                (res) => {
                    if(!res.data || res.data.error){
                        toastr.error('Error interno al eliminar usuario', 'Error')
                    } else {
                       setUsuarios([]); 
                       for(let i=0;i<res.data.users.length;i++){
                            usuariosTmp.push(
                                {
                                   "id": res.data.users[i].id,
                                   "name": res.data.users[i].name,
                                   "fecha_nacimiento": res.data.users[i].fecha_nacimiento !== '0000-00-00' && res.data.users[i].fecha_nacimiento !== '' ? format(parseISO(res.data.users[i].fecha_nacimiento), 'dd-MM-yyyy') : '',
                                   "email": res.data.users[i].email,
                                   "rut": Format(res.data.users[i].rut),
                                   "rol": JSON.parse(res.data.users[i].rol).join(", "),
                                   "creado": format(parseISO(res.data.users[i].created_at), 'dd-MM-yyyy H:mm'),
                                   "activo":(res.data.users[i].active == 1) ? 'SI' : 'NO' 
                               }
                            );
                        }
                        setUsuarios(usuariosTmp);
                        setLoadingNew(false);
                        setContact();
                        setDeleteModal(false)
                        setPaginaInicio(0);  
                        toastr.success(res.data.message, 'OK');
                        //tog_standard();
                        setFormValues({
                            id: 0,
                            rut: '',
                            nombre: '',
                            apellidos: '',
                            email: '',
                            celular: '',
                            nacimiento: '',
                            direccion: '',
                            tallaSelect: 0,
                            rutApoderado: '',
                            beca: false,
                            checkAlumno: false,
                            checkApoderado: false,
                            checkEncargado: false,
                            checkProfesor: false,
                            active: true
                        });
                      }
                })
            .catch((error) => {
                setLoadingNew(false);
                if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                   toastr.error(error.message, 'Error')  
                  }
             })
        }
      };
    
    const guardarNuevo = (e) => {
        
        e.preventDefault();
        toastr.options = {
            closeButton: true,
            progressBar: true,
            showEasing: 'linear',
            hideEasing: 'linear',
            hideDuration: 1
          };
        toastr.clear();
        setLoadingNew(true);
        
        let usuariosTmp = [];

        axios.post(`/users`, formValues)
            .then(
                (res) => {

                    if(!res.data || res.data.error){
                        toastr.error('Error interno al crear usuario', 'Error')
                    } else {
                       setUsuarios([]); 
                       for(let i=0;i<res.data.users.length;i++){
                            usuariosTmp.push(
                                {
                                   "id": res.data.users[i].id,
                                   "name": res.data.users[i].name,
                                   "fecha_nacimiento": res.data.users[i].fecha_nacimiento !== '0000-00-00' && res.data.users[i].fecha_nacimiento !== '' ? format(parseISO(res.data.users[i].fecha_nacimiento), 'dd-MM-yyyy') : '',
                                   "email": res.data.users[i].email,
                                   "rut": Format(res.data.users[i].rut),
                                   "rol": JSON.parse(res.data.users[i].rol).join(", "),
                                   "creado": format(parseISO(res.data.users[i].created_at), 'dd-MM-yyyy H:mm'),
                                   "activo":(res.data.users[i].active == 1) ? 'SI' : 'NO' 
                               }
                            );
                        }
                        setUsuarios(usuariosTmp);
                        setLoadingNew(false);
                        toastr.success(res.data.message, 'OK');
                        tog_standard();
                        setFormValues({
                            id: 0,
                            rut: '',
                            nombre: '',
                            apellidos: '',
                            email: '',
                            celular: '',
                            nacimiento: '',
                            direccion: '',
                            tallaSelect: 0,
                            rutApoderado: '',
                            beca: false,
                            checkAlumno: false,
                            checkApoderado: false,
                            checkEncargado: false,
                            checkProfesor: false,
                            active: true
                        });
                      }
                })
            .catch((error) => {
                setLoadingNew(false);
                if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                   toastr.error(error.message, 'Error')  
                  }
             })

    };
    
     const actualizar = (e) => {
        
        e.preventDefault();
        toastr.options = {
            closeButton: true,
            progressBar: true,
            showEasing: 'linear',
            hideEasing: 'linear',
            hideDuration: 1
          };
        toastr.clear();
        setLoadingNew(true);
        
        let usuariosTmp = [];

        axios.put(`/users`, formValues)
            .then(
                (res) => {

                    if(!res.data || res.data.error){
                        toastr.error('Error interno al actualizar usuario', 'Error')
                    } else {
                       setUsuarios([]); 
                       for(let i=0;i<res.data.users.length;i++){
                            usuariosTmp.push(
                                {
                                   "id": res.data.users[i].id,
                                   "name": res.data.users[i].name,
                                   "fecha_nacimiento": res.data.users[i].fecha_nacimiento !== '0000-00-00' && res.data.users[i].fecha_nacimiento !== '' ? format(parseISO(res.data.users[i].fecha_nacimiento), 'dd-MM-yyyy') : '',
                                   "email": res.data.users[i].email,
                                   "rut": Format(res.data.users[i].rut),
                                   "rol": JSON.parse(res.data.users[i].rol).join(", "),
                                   "creado": format(parseISO(res.data.users[i].created_at), 'dd-MM-yyyy H:mm'),
                                   "activo":(res.data.users[i].active == 1) ? 'SI' : 'NO' 
                               }
                            );
                        }
                        setUsuarios(usuariosTmp);
                        setLoadingNew(false);
                        toastr.success(res.data.message, 'OK');
                        tog_standard();
                        setFormValues({
                            id: 0,
                            rut: '',
                            nombre: '',
                            apellidos: '',
                            email: '',
                            celular: '',
                            nacimiento: '',
                            direccion: '',
                            tallaSelect: 0,
                            rutApoderado: '',
                            beca: false,
                            checkAlumno: false,
                            checkApoderado: false,
                            checkEncargado: false,
                            checkProfesor: false,
                            active: true
                        });
                      }
                })
            .catch((error) => {
                setLoadingNew(false);
                if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                   toastr.error(error.message, 'Error')  
                  }
             })

    };
    
    const onFocusRut = (rut, name) => 
    {
        if (rut != ''){
            rut = clean(rut);
            setFormValues({
                ...formValues,
                [name]: rut,
              });
        }
    };
    
    const export_csv = () => {
        setLoadingTable(true);
        axios.get('/users-seguro', {}).then((res) => {
            if(!res.data){
            } else {
               let csvContent = "data:text/csv;charset=utf-8,";
                                                
                if(res.data.length > 0){
                    
                    const timestamp = Date.now();
                    const unixTimestamp = Math.floor(timestamp / 1000);
                    csvContent += "NOMBRE;RUT;FECHA NACIMIENTO;RUT APODERADO;EMAIL APODERADO\r\n";

                    res.data.forEach(function(value, index) {
                        csvContent += value.name+";";
                        csvContent += Format(value.rut)+";";
                        csvContent += value.fecha_nacimiento !== '0000-00-00' && value.fecha_nacimiento !== '' ? format(parseISO(value.fecha_nacimiento), 'dd-MM-yyyy')+";" : "-;";
                        csvContent += Format(value.rut_apoderado)+";";
                        csvContent += value.email_apoderado+";\r\n";
                    });

                    let encodedUri = encodeURI(csvContent);
                    let link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "seguro_alumnos_"+unixTimestamp+".csv");
                    document.body.appendChild(link);
                    link.click();
              
                    setLoadingTable(false);
                }
            }
          }, (error) => {
              setLoadingTable(false);
              toastr.error('Error interno al obtener información', 'Error')
        });  
    };
    
    const refreshTable = () => {
        setLoadingTable(true);
        setUsuarios([]);
        let usuariosTmp = [];
        axios.get('/users', {}).then((response) => {
            if(!response.data){
            } else {
               for(let i=0;i<response.data.length;i++){
                    usuariosTmp.push(
                        {
                           "id": response.data[i].id,
                           "name": response.data[i].name,
                           "fecha_nacimiento": response.data[i].fecha_nacimiento !== '0000-00-00' && response.data[i].fecha_nacimiento !== '' ? format(parseISO(response.data[i].fecha_nacimiento), 'dd-MM-yyyy') : '',
                           "email": response.data[i].email,
                           "rut": Format(response.data[i].rut),
                           "rol": JSON.parse(response.data[i].rol).join(", "),
                           "creado": format(parseISO(response.data[i].created_at), 'dd-MM-yyyy H:mm'),
                           "activo": (response.data[i].active == 1) ? 'SI' : 'NO' 
                       }
                    );
                }
               setUsuarios(usuariosTmp);
               setLoadingTable(false);
            }
          }, (error) => {
        });  
    };
    
    const onBlurRut = (rut, name) => 
    {
        if (rut != '') {
            if (rut.length > 3) {
                rut = Format(rut);
                setFormValues({
                ...formValues,
                [name]: rut,
              });
            }
        } 
    };
    
    const rowClick = (cell) => 
    {   
        let id = cell.row.cells[0].value;
        setLoadingTable(true);
        axios.get('/users/'+id ,{}).then((response) => {
               if(!response.data || response.data.error){
                    toastr.error('Error interno al obtener información', 'Error');
                    setLoadingTable(false);
               } else {
                   let usuario = response.data[0];
                   let nombre = usuario.name.split(' ');
                   let apellidos = '';
                   if(nombre.length >= 4){
                       nombre = usuario.name.split(' ')[0]+' '+usuario.name.split(' ')[1];
                       apellidos = usuario.name.split(' ')[2]+' '+usuario.name.split(' ')[3]
                   }else if(nombre.length == 3){
                       nombre = usuario.name.split(' ')[0];
                       apellidos = usuario.name.split(' ').slice(1).join(' ')+' '+usuario.name.split(' ').slice(2).join(' ');
                   }else{
                       nombre = usuario.name.split(' ')[0];
                       apellidos = usuario.name.split(' ')[1];
                   }
                   
                   setFormValues({
                        id: usuario.id,
                        rut: Format(usuario.rut),
                        nombre: nombre,
                        apellidos: apellidos,
                        email: usuario.email,
                        celular: usuario.celular,
                        nacimiento: usuario.fecha_nacimiento,
                        direccion: usuario.direccion,
                        tallaSelect: usuario.talla,
                        rutApoderado: usuario.rut_apoderado,
                        beca: (usuario.beca == 1) ? true : false,
                        checkAlumno: usuario.rol.includes('alumno'),
                        checkApoderado: usuario.rol.includes('apoderado'),
                        checkEncargado: usuario.rol.includes('administrador'),
                        checkProfesor: usuario.rol.includes('profesor'),
                        active: (usuario.active == 1) ? true : false
                    }); 
                    setLoadingTable(false);
                    tog_standardEdit();
                  }
            }, (error) => {
                 setLoadingTable(false);
                 toastr.error('Error interno al obtener información', 'Error')
           });  
        
    }
    
    const handleFilterChange = (columnId, value) => {
   
        setFilters({
          ...filters,
          [columnId]: value,
        });

        // Establece el filtro en la tabla
        setFilter(columnId, value);
      };

    
    const handleColumnSort = (column) => {

        let newSort = [];
        if(!column.isSorted && typeof column.isSortedDesc === 'undefined'){
             newSort = [{ id: column.id, desc: false }];
        }else if ( column.isSorted && column.isSortedDesc === false){
             newSort = newSort = [{ id: column.id, desc: true }];
        }else {
            newSort = newSort = [{ id: 'name', desc: false }];
        }
        setPaginaInicio(0);
        setSorting(newSort);
    };
    
    const handleCambioPagina = (valor) => {
        setPaginaInicio(valor);
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
      };
    
      useEffect(() => {
          
        if(jsonString.indexOf('administrador') === -1){
            props.router.navigate('/pages-404');
            return;
        }  
        console.log("ASAAA");
        let usuariosTmp = [];
 
        axios.get('/users', {}).then((response) => {
               if(!response.data || response.data.error){
                   toastr.error('Error interno al obtener información', 'Error')
               } else {
                  for(let i=0;i<response.data.length;i++){
                     
                       usuariosTmp.push(
                           {
                              "id": response.data[i].id,
                              "name": response.data[i].name,
                              "fecha_nacimiento": response.data[i].fecha_nacimiento !== '0000-00-00' && response.data[i].fecha_nacimiento !== "" ? format(parseISO(response.data[i].fecha_nacimiento), 'dd-MM-yyyy') : '',
                              "email": response.data[i].email,
                              "rut": Format(response.data[i].rut),
                              "rol": JSON.parse(response.data[i].rol).join(", "),
                              "creado": format(parseISO(response.data[i].created_at), 'dd-MM-yyyy H:mm'),
                              "activo": (response.data[i].active == 1) ? 'SI' : 'NO' 
                          }
                       );
                   }
                  setUsuarios(usuariosTmp);
                  setLoadingTable(false);
               }
             }, (error) => {
                  setLoadingTable(false);
                  toastr.error('Error interno al obtener información', 'Error')
           });  
     }, []);
     
    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id',
                //isHidden: true,
            },
            {
                Header: 'RUT',
                accessor: 'rut',
            },
            {
                Header: 'Nombre',
                accessor: 'name'
            },
            {
                Header: 'Fecha Nacimiento',
                accessor: 'fecha_nacimiento'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'rol',
                accessor: 'rol'
            },
            {
                Header: 'Creado',
                accessor: 'creado'
            },
            {
                Header: 'Activo',
                accessor: 'activo'
            },
            {
                Header: 'Acciones',
                accessor: 'acciones',
                disableFilters: true,
                filterable: false,
                Cell: (cellProps) => {
                  return (
                    <div className="d-flex gap-3">
                        <div
                        style={{cursor:'pointer'}} 
                        className="text-success"
                        onClick={() => { rowClick(cellProps); }}
                      >
                        <i style={{cursor:'pointer'}}  className="mdi mdi-pencil font-size-18" id="edittooltip" />
                        <UncontrolledTooltip placement="top" target="edittooltip">
                          Editar
                        </UncontrolledTooltip>
                      </div>
                      <div
                        style={{cursor:'pointer'}} 
                        className="text-danger"
                        onClick={() => {
                          const orderData = cellProps.row.original;
                          onClickDelete(orderData);
                        }}
                      >
                        <i style={{cursor:'pointer'}}  className="mdi mdi-delete font-size-18" id="deletetooltip" />
                        <UncontrolledTooltip placement="top" target="deletetooltip">
                          Eliminar
                        </UncontrolledTooltip>
                      </div>
                    </div>
                  );
                }
              },
        ],
        []
    );

    document.title = "Usuarios | Listado";
    //console.log(usuarios);

    return (
            
        <div className="page-content">
           <DeleteModal
            show={deleteModal}
            itemName={itemName}
            onDeleteClick={handleDeleteUser}
            onCloseClick={() => setDeleteModal(false)}
          />
            <Container fluid={true}>
                <Breadcrumbs title="Usuarios" breadcrumbItem="Listado" />
                <Row>
            <Col className="col-12">
              <Card>
                <CardBody >
                  <CardTitle className="h4">Listado de Usuarios</CardTitle>
                  {/*(usuarios  == "" ||*/ loadingTable/*) */ &&  
                  <div style={{ height: '65vh' }}  className="textoCentrado spinner-content-new" >
                  <Spinner className="ms-2" color="primary" />
                  </div>
                  }
                  {!loadingTable  &&
                  <div style={{ display: !loadingTable ? '' : 'none' }} className="table-hover">
                    <TableContainer
                      columns={columns}
                      data={usuarios}
                      handleRefreshClick={() => { refreshTable(); }}
                      handleUserClick={() => { tog_standard(); }}
                      handleExportCsvClick={() => { export_csv(); }}
                      isAddUserList={true}
                      customPageSizeOptions={true}
                      isGlobalFilter={false}
                      isAddOptions={false}
                      isRefresh={true}
                      sortParams={sorting}
                      handleFilterChange={(e,val) => { handleFilterChange(e,val); }}
                      handleColumnSort={(e) => { handleColumnSort(e); }}
                      createText="Crear nuevo"
                      exportCsv="Exportar Seguro"
                      customPageSize={10}
                      className="custom-header-css"
                      rowClick={false}
                      handleCambioPagina={(e)=> { handleCambioPagina(e); }}
                      paginaInicio={paginaInicio}
                      handleRowClick={(e) => { rowClick(e); }}
                      />
                  </div>
                 }
                 <Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard();
                      }}
                      keyboard={false}
                      backdrop={loadingNew ? 'static' : true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModal">
                         {!edit ? 'Crear nuevo usuario' : 'Editar usuario'}
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setEdit(false);
                            setmodal_standard(false);
                          }}
                          disabled={loadingNew}
                          className="btn-close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                        </button>
                      </div>
                      <div className="modal-body">
                        <Form>
                            <div className="form-floating mb-3">
                                <h5 className="font-size-14">
                                  <i className="mdi mdi-arrow-right text-primary"></i>{" "}
                                  Seleccione tipo de cuenta
                                </h5>
                                <div className="ms-2 mt-2">
                                  <div className="form-check form-check-primary mb-1 mt-1">
                                    <input
                                      disabled={loadingNew}
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customCheckcolor1"
                                      name="checkApoderado"
                                      checked={formValues.checkApoderado}
                                     onChange={e => {
                                        handleCheckChange(e);
                                      }}
                                      onClick={e => {
                                        handleCheckChange(e);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="customCheckcolor1"
                                    >
                                      Apoderado
                                    </label>
                                  </div>
                                  <div className="form-check form-check-success mb-2 mt-2">
                                    <input
                                      disabled={loadingNew}
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customCheckcolor2"
                                      name="checkAlumno"
                                      checked={formValues.checkAlumno}
                                      onChange={e => {
                                        handleCheckChange(e);
                                      }}
                                      onClick={e => {
                                        handleCheckChange(e);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="customCheckcolor2"
                                    >
                                      Alumno
                                    </label>
                                  </div>
                                  <div className="form-check form-check-warning mt-1">
                                    <input
                                      disabled={loadingNew}
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customCheckcolor6"
                                      name="checkProfesor"
                                      checked={formValues.checkProfesor}
                                      onChange={e => {
                                        handleCheckChange(e);
                                      }}
                                      onClick={e => {
                                        handleCheckChange(e);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="customCheckcolor6"
                                    >
                                      Profesor
                                    </label>
                                  </div>
                                  <div className="form-check form-check-danger mt-1">
                                    <input
                                      disabled={loadingNew}
                                      type="checkbox"
                                      className="form-check-input"
                                      id="customCheckcolor5"
                                      name="checkEncargado"
                                      checked={formValues.checkEncargado}
                                      onChange={e => {
                                        handleCheckChange(e);
                                      }}
                                       onClick={e => {
                                        handleCheckChange(e);
                                      }}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="customCheckcolor5"
                                    >
                                      Encargado Sistema
                                    </label>
                                  </div>
                                </div>
                            </div>
                            <div className="form-floating mb-3">
                              <input 
                              disabled={loadingNew} 
                              name="rut" 
                              type="text" 
                              onFocus={(e) => onFocusRut(e.target.value, e.target.name)}
                              onBlur={(e) => onBlurRut(e.target.value, e.target.name)}
                              onChange={handleChange} 
                              value={formValues.rut} 
                              className="form-control" 
                              id="rutInput" 
                              placeholder="Enter Name" />
                              <label htmlFor="rutInput">RUT</label>
                            </div>
                            <div className="form-floating mb-3">
                              <input disabled={loadingNew} name="nombre" onChange={handleChange} value={formValues.nombre} type="text" className="form-control" id="nombreInput" placeholder="Enter Name" />
                              <label htmlFor="nombreInput">Nombre</label>
                            </div>
                            <div className="form-floating mb-3">
                              <input disabled={loadingNew} name="apellidos" type="text" onChange={handleChange} value={formValues.apellidos} className="form-control" id="apellidosInput" placeholder="Enter Name" />
                              <label htmlFor="apellidosInput">Apellidos</label>
                            </div>
                            {(formValues.checkEncargado || formValues.checkApoderado || formValues.checkProfesor) &&
                            <React.Fragment>
                            <div className="form-floating mb-3">
                              <input disabled={loadingNew} name="celular" onChange={handleChange} value={formValues.celular} type="text" className="form-control" id="celularInput" placeholder="Enter Name" />
                              <label htmlFor="celularInput">Celular</label>
                            </div>
                            <div className="form-floating mb-3">
                              <input disabled={loadingNew} name="email" type="text" onChange={handleChange} value={formValues.email} className="form-control" id="emailInput" placeholder="Enter Name" />
                              <label htmlFor="emailInput">E-mail</label>
                            </div>
                            </React.Fragment>  
                            }
                            { formValues.checkAlumno &&
                            <React.Fragment>
                           <div className="form-floating mb-3">
                              <Flatpickr
                                id="nacimientoInput"
                                disabled={loadingNew}
                                onChange={e => {
                                    handleDateChange(e,'nacimiento');
                                  }}
                                value={formValues.nacimiento}
                                name="nacimiento"
                                placeholder="dd M,yyyy"
                                className="form-control d-block"
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  locale: {
                                    ...Spanish,
                                        months: {
                                            ...Spanish.months,
                                            ...Spanish.longhand
                                        }
                                    },
                                }}
                              />
                              <Label htmlFor="nacimientoInput">Fecha de Nacimiento</Label>
                          </div>
                          <div className="form-floating mb-3">
                              <input disabled={loadingNew} type="text" onChange={handleChange} value={formValues.direccion} className="form-control" name="direccion" id="direccionInput" placeholder="Enter Name" />
                              <label htmlFor="direccionInput">Dirección</label>
                            </div>
                            <Row>
                            <Col md={6}>
                                <div className="form-floating mb-3">
                                  <select disabled={loadingNew} name="tallaSelect" onChange={handleChange} value={formValues.tallaSelect} id="tallaSelect" className="form-select">
                                    <option value="0">Seleccione</option>
                                    <option value="6">6</option>
                                    <option value="8">8</option>
                                    <option value="10">10</option>
                                    <option value="12">12</option>
                                    <option value="14">14</option>
                                    <option value="16">16</option>
                                    <option value="XS">XS</option>
                                    <option value="S">S</option>
                                    <option value="M">M</option>
                                    <option value="L">L</option>
                                    <option value="XL">XL</option>
                                  </select>
                                  <label htmlFor="tallaSelect">Talla</label>
                                </div>
                              </Col>
                              <Col md={6}>
                                <FormGroup className="mb-3">
                                        <Label
                                          htmlFor="becaInput"
                                          className="form-label"
                                        >
                                          Beca
                                        </Label>
                                        <Switch
                                    id="becaInput"
                                    disabled={loadingNew}
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    name="beca"
                                    className="input-group me-1 mb-sm-8 mb-2"
                                    onColor="#02a499"
                                    onChange={e => {
                                        handleSwitchChange(e,'beca');
                                      }}
                                    checked={formValues.beca}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <div className="form-floating mb-3">
                              <input 
                              disabled={loadingNew} 
                              type="text" 
                              name="rutApoderado" 
                              onChange={handleChange}
                              onFocus={(e) => onFocusRut(e.target.value, e.target.name)}
                              onBlur={(e) => onBlurRut(e.target.value, e.target.name)}
                              value={formValues.rutApoderado} 
                              className="form-control" 
                              id="rutApoderadoInput" 
                              placeholder="Enter Name" />
                              <label htmlFor="rutApoderadoInput">RUT Apoderado</label>
                            </div>
                            </React.Fragment>
                            }
                            <Row>
                              <Col md={12}>
                                <FormGroup className="mb-3">
                                        <Label
                                          htmlFor="activeInput"
                                          className="form-label"
                                        >
                                          Activo
                                        </Label>
                                        <Switch
                                    id="activeInput"
                                    disabled={loadingNew}
                                    uncheckedIcon={<InactiveSymbol />}
                                    checkedIcon={<ActiveSymbol />}
                                    name="active"
                                    className="input-group me-1 mb-sm-8 mb-2"
                                    onColor="#02a499"
                                    onChange={e => {
                                        handleSwitchChange(e,'active');
                                      }}
                                    checked={formValues.active}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                      </div>
                      <div className="modal-footer">
                      <div style={{
                            display: 'inline-block',
                            alignItems: 'center',}}>
                        <div style={
                            {margin: 10,
                            position: 'relative',}}>
                        <button
                          disabled={loadingNew}  
                          type="button"
                          onClick={() => {
                            setEdit(false);  
                            tog_standard();
                          }}
                          className="btn btn-secondary me-2"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        {!edit ? (
                        <button
                          disabled={loadingNew}
                          type="button"
                          className="btn btn-primary "
                          onClick={e => {
                            guardarNuevo(e);
                          }}
                        >
                        <span style={{ visibility: !loadingNew ? 'visible' : 'hidden' }}>Guardar</span>
                        </button>)
                        : (<button
                          disabled={loadingNew}
                          type="button"
                          className="btn btn-info "
                          onClick={e => {
                            actualizar(e);
                          }}
                        >
                        <span style={{ visibility: !loadingNew ? 'visible' : 'hidden' }}>Actualizar</span>
                        </button>)}
                        <Spinner style={{ display: loadingNew ? 'block' : 'none',
                        color: 'red',
                        '--bs-spinner-width':'1.7rem',
                        '--bs-spinner-height':'1.7rem',
                        position: 'absolute',
                        top: '46%',
                        left: '59%',
                        marginTop: -12,
                        marginLeft: -12}}  className="ms-2 " color="primary" />
                      </div>
                       </div>
                        </div>
                    </Modal>
                </CardBody>
                </Card>
                </Col >
                 </Row>
            </Container>
        </div>
    );
}
UsuariosList.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default withRouter(UsuariosList);