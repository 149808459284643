import PropTypes from 'prop-types'
import React, {  useState } from "react"
import { Modal, ModalBody, Col,
  Row,
  Card,
  CardBody, Label  } from "reactstrap";
  //import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"

import { format, parseISO  } from 'date-fns';

const DeleteCuotaModal = ({ show, onDeleteClick, onCloseClick, itemName }) => {

  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <i className="bx bx-dollar-circle"></i>
            </div>
          </div>
          <p className="font-size-16 mb-3">¿Estás seguro {itemName}?</p>
          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-danger" onClick={onDeleteClick}>Confirmar</button>
            <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Cerrar</button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

DeleteCuotaModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default DeleteCuotaModal
