import PropTypes from "prop-types";
import React, { useMemo, useState, useEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form, Spinner } from "reactstrap";
import {validate as Validate, clean, format as Format} from 'rut.js';
//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";

const ResetPasswordPage = props => {

     const [loading,setLoading] = useState(false);
     const [mostrar,setMostrar] = useState(false);
     const [mostrarOk,setMostrarOk] = useState(false);
     const [validacion,setValidation] = useState(false);
     const [rut,setRut] = useState('');
     const [token,seToken ] = useState('');   
     
     const clearToast = () => {
        toastr.clear();
      } 
    
    useEffect(() => {  

      const query = new URLSearchParams(props.router.location.search);
      const token = query.get('token');
      const rut = query.get('rut');
      setLoading(true);
      axios.get('/checkloggin', {}).then((response) => {
          if(response.data && response.data.check == true){
             props.router.navigate("/login");
          } else {
              if(token && token != ""){
                axios.post('/checktoken', {'token':token,'rut':rut}).then((response) => {
                    if(!response.data){
                        setMostrar(false);
                        setLoading(false);
                        setValidation(true);
                    } else {
                        setMostrar(true);
                        setLoading(false);
                        setValidation(true);
                        setRut(rut);
                        seToken(token);
                    }
                  }, (error) => {
                      setMostrar(false);
                      setLoading(false);
                      setValidation(true);
                  });
            } else {
                setMostrar(false);
                setLoading(false);
                setValidation(true);
            }
              
          }
        }, (error) => {
            toastr.error(error, 'Error');
        });
      
  }, []);

  //meta title
  document.title = "Reset Password | Skote - React Admin & Dashboard Template";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password1: '',
      password2: ''
    },
    validationSchema: Yup.object({
      password1: Yup.string().required("Por favor ingresa contraseña"),
      password2: Yup.string().required("Por favor repita contraseña").oneOf([Yup.ref("password1")], "Contraseñas no son iguales"),
    }),
    onSubmit: (values) => {
     setLoading(true);
     let password1 = values.password1;
     let password2 = values.password2;
     axios.put(`/users/password/update`,{rut, token,password1, password2})
        .then(
            (res) => {
                setLoading(false);
                if(!res.data){
                    setMostrar(false);
                    setValidation(true);
                    validation.setFieldValue("password1", "");
                    validation.setFieldValue("password2", "");
                    //this.setState({  loading : false, password1: '', password2:'', validacion:true, mostrar:false });
                } else {
                    
                    setMostrarOk(true);
                    setMostrar(true);
                    setValidation(true);
                    seToken(token);
                    setRut(rut);
                    //this.setState({ email: email, token: token, mostrar : true, loading : false , validacion:true, mostrarOk:true});
                }
            })
        .catch((error) => {
             setLoading(false);
            if (error.response) {
                toastr.error(error.response.data.message, 'Error');
              } else if (error.request) {
                toastr.error(error.request, 'Error');
              } else {
                toastr.error(error.message, 'Error');
              }
         });
     
    }
  });
  
  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  {!mostrarOk &&
                  <div className="p-2">
                    { (mostrar && validacion) &&
                  
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">Contraseña</Label>
                        <Input
                          name="password1"
                          disabled={loading}
                          className="form-control"
                          placeholder="Ingresa contraseña"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={(e) => {
                            validation.handleBlur;
                          }}
                          value={validation.values.password1 || ""}
                          invalid={
                            validation.touched.password1 && validation.errors.password1 ? true : false
                          }
                        />
                        {validation.touched.password1 && validation.errors.password1 ? (
                          <FormFeedback type="invalid">{validation.errors.password1}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label className="form-label">Repita contraseña</Label>
                        <Input
                          name="password2"
                          disabled={loading}
                          className="form-control"
                          placeholder="Repita contraseña"
                          type="password"
                          onChange={validation.handleChange}
                          onBlur={(e) => {
                            validation.handleBlur;
                          }}
                          value={validation.values.password2 || ""}
                          invalid={
                            validation.touched.password2 && validation.errors.password2 ? true : false
                          }
                        />
                        {validation.touched.password2 && validation.errors.password2 ? (
                          <FormFeedback type="invalid">{validation.errors.password2}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                        {!loading &&
                          <button
                            disabled={loading}
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                           Recuperar
                          </button>
                          }
                        </Col>
                      </Row>
                    </Form>
                    } { (!mostrar && validacion) &&
                    <React.Fragment>      
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        El enlace a caducado o el RUT no es válido. 
                      </Alert>
                        <Row className="mb-3">
                        <Col className="text-end">
                          <Link to="/login" className="">
                          <button className="btn btn-secondary btn-block btn-large text-white w-100">Regresar</button>
                          </Link>
                          </Col>
                          </Row>
                        </React.Fragment>
                    }
                  </div>
                  }
                  {mostrarOk &&
                     <div className="p-2">
                        <Alert color="success" style={{ marginTop: "13px" }}>
                            Has reestablecido tu contraseña correctamente
                        </Alert>
                        <Link to="/login" className="">
                          <button className="btn btn-secondary btn-block text-white w-100">Regresar</button>
                          </Link>
                     </div>
                  }
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Regresar al {" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Ingreso
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ResetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ResetPasswordPage);
