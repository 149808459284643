// src/components/filter.
import React, { useMemo, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import withRouter from "components/Common/withRouter";

//import components
import Breadcrumbs from 'components/Common/Breadcrumb';
import TableContainer from 'components/Common/TableContainer';

import Switch from "react-switch";
import Select from "react-select";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { SketchPicker } from "react-color";
import ColorPicker from "@vtaits/react-color-picker";

import DeleteModal from "components/Common/DeleteModal";
import ConfirmarEmailModal from "components/Common/ConfirmarEmailModal";
//Import Flatepicker
//import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"

import {validate as Validate, clean, format as Format} from 'rut.js'
import { format, parseISO } from 'date-fns';

import {
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  Tooltip,
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Container,
  Spinner,
  Badge,
  Modal,
  UncontrolledPopover,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Label,
  Form,
  Input,
  InputGroup,
  UncontrolledTooltip
} from "reactstrap";

const InscripcionesList = props => {

  const clearToast = () => {
    toastr.clear();
  }

    const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Si
      </div>
    );
  };
  
   const ActiveSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        No
      </div>
    );
  };
  
  const InactiveSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
        {" "}
        Si
      </div>
    );
  };
    
    const [modal_standard, setmodal_standard] = useState(false);
    const [modal_standard_new, setmodal_standard_new] = useState(false);
    const [usuarios, setUsuarios] = useState([]);
    const [loadingTable, setLoadingTable] = useState(true);
    const [loadingNew, setLoadingNew] = useState(false);
    const [edit, setEdit] = useState(false);
    const [newInscripcion, setNewInscripcion] = useState(false);
    const [sorting, setSorting] = useState([{ id: 'name', desc: false }]);
    const [paginaInicio, setPaginaInicio] = useState(0);
    const [filters, setFilters] = useState({});
    const [deleteModal, setDeleteModal] = useState(false);
    const [confirmarEmailModal, setConfirmarEmailModal] = useState(false);
    const [contact, setContact] = useState();
    const [itemName, setItemName] = useState('');
    const [notificaId, setNotificaId] = useState(0);
    const [envioOK, setEnvioOK] = useState(false);
    const [cargandoMail, setCargandoMail] = useState(false);
    const [notificaNombre, setNotificaNombre] = useState('');
    const [notificaCursos, setNotificaCursos] = useState('');
    const [notiAnteriores, setNotiAnteriores] = useState([]);
    
    const jsonString = (localStorage.getItem("authUser") && localStorage.getItem("authUser").length > 0) ? JSON.parse(JSON.parse(localStorage.getItem("authUser"))[0].rol) : [];
   
    /*datos inscripcion*/
    const [formValues, setFormValues] = useState({
        id: 0,
        nombre : '',
        rut: '',
        user_id: 0,
        course_id: 0,
        active: true,
        cycle: '',
        course_out:0,
        course_in: 0,
        delete: 0,
        index:0
      });
    
    const handleDateChange = (selectedDates, field) => {
        setFormValues({
          ...formValues,
          [field]: selectedDates,
        });
      };

      const handleCheckChange = (event) => {
       const { name } = event.target; 
        setFormValues({
          ...formValues,
          [name]: event.target.checked
        });
      };
      
      const handleSwitchChange = (event,field) => {
        setFormValues({
          ...formValues,
          [field]: event,
        });
      };
      
      const onClickDelete = (inscription,nombre) => {
          setItemName('curso inscrito '+inscription.curso+' de '+nombre+'?')
          setContact(inscription);
          setDeleteModal(true);
    };
    
    const export_csv = () => {
        setLoadingTable(true);
        axios.get('/inscriptions-listado', {}).then((res) => {
            if(!res.data){
            } else {
               let csvContent = "data:text/csv;charset=utf-8,";
                                                
                if(res.data.length > 0){
                    
                    const timestamp = Date.now();
                    const unixTimestamp = Math.floor(timestamp / 1000);
                    csvContent += "CURSO;PROFESOR;RUT ALUMNO;ALUMNO;FECHA NACIMIENTO;ESTADO;CELULAR ALUMNO;CELULAR APODERADO;\r\n";

                    res.data.forEach(function(value, index) {
                        csvContent += value.curso+";";
                        csvContent += value.profesor+";";
                        csvContent += Format(value.rut)+";";
                        csvContent += value.estudiante+";";
                        csvContent += value.fecha_nacimiento !== '0000-00-00' && value.fecha_nacimiento !== "" ? format(parseISO(value.fecha_nacimiento), 'dd-MM-yyyy')+";" : ';';
                        csvContent += value.estado+";";
                        csvContent += value.telefono1+";";
                        csvContent += value.telefono2+";\r\n";
                        
                    });

                    let encodedUri = encodeURI(csvContent);
                    let link = document.createElement("a");
                    link.setAttribute("href", encodedUri);
                    link.setAttribute("download", "listado_inscripciones_"+unixTimestamp+".csv");
                    document.body.appendChild(link);
                    link.click();
              
                    setLoadingTable(false);
                }
            }
          }, (error) => {
              setLoadingTable(false);
              toastr.error('Error interno al obtener información', 'Error')
        });  
    };

     const handleDeleteUser = () => {

        if (contact && contact.id) {
            let usuariosTmp = [];
            axios.delete(`/inscriptions`, {data : contact})
            .then(
                (res) => {
                    if(!res.data || res.data.error){
                        toastr.error('Error interno al eliminar inscripción', 'Error')
                    } else {
                       setUsuarios([]); 
                       for(let i=0;i<res.data.inscriptions.length;i++){
                            usuariosTmp.push(
                                {
                                   "id": res.data.inscriptions[i].user_id,
                                   "name": res.data.inscriptions[i].user_name,
                                   "fecha_nacimiento": res.data.inscriptions[i].fecha_nacimiento !== '0000-00-00' && res.data.inscriptions[i].fecha_nacimiento !== "" ? format(parseISO(res.data.inscriptions[i].fecha_nacimiento), 'dd-MM-yyyy') : '',
                                   "email": res.data.inscriptions[i].user_email,
                                   "rut": Format(res.data.inscriptions[i].rut),
                                   "cursos": res.data.inscriptions[i].course_names,
                                   "estado": (res.data.inscriptions[i].course_names != "") ? res.data.inscriptions[i].pagados : "",
                                   "activo":(res.data.inscriptions[i].active == 1) ? 'SI' : 'NO' 
                               }
                            );
                        }                        
                        setUsuarios(usuariosTmp);
                        setLoadingNew(false);
                        setContact();
                        setDeleteModal(false);
                        setPaginaInicio(0);  
                        toastr.success(res.data.message, 'OK');
                        //tog_standard();
                        setFormValues({
                            ...formValues,
                            ['id']: 0,
                            //nombre : '',
                            //rut: '',
                            //user_id: 0,
                            ['course_id']: 0,
                            ['active']: true,
                            ['cycle']: '',
                            ['course_out']:0,
                            ['course_in']: 0,
                            ['delete']: 0,
                            ['index'] : 0,
                            ['inscription_in']: res.data.inscription_in,
                            ['inscription_out']: res.data.inscription_out
                        });
                      }
                })
            .catch((error) => {
                setLoadingNew(false);
                if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                   toastr.error(error.message, 'Error')  
                  }
             })
        }
      };
    
    const handleChange = (event) => {
        const { name, value, tabIndex, selectedIndex } = event.target;
        let index = selectedIndex-1;
        
        if(name == "course_in"){
            if(value == 0){
                setFormValues({
                    ...formValues,
                    ['course_in']: value,
                    ['cycle_in'] : '',
                    ['duedate_in'] : '',
                    ['nextduedate_in'] : '',
                    ['price_in'] : 0,
                    ['m_price_in'] : 0,
                    ['index'] : 0,
                    ['price_out_first'] : ''
                });
            } else {
                setFormValues({
                    ...formValues,
                    ['course_in']: value,
                    ['cycle_in'] : index > -1 ? formValues.inscription_in[index].cycle : '',
                    ['duedate_in'] : index > -1 ? formValues.inscription_in[index].duedate : '',
                    ['nextduedate_in'] : index > -1 ? formValues.inscription_in[index].nextduedate : '',
                    ['price_in'] : index > -1 ? formValues.inscription_in[index].price  : 0,
                    ['m_price_in'] : index > -1 ? formValues.inscription_in[index].m_price  : 0,
                    ['index'] : index > -1 ? index  : 0,
                    ['price_out_first'] : index > -1 ? formValues.inscription_in[index].price_out_first  : ''
                });
            }
        } else{
            if(name == "course_out"){
                setFormValues({
                    ...formValues,
                    ['course_out']: value,
                    ['cycle_out'] : '',
                    ['duedate_out'] : '',
                    ['nextduedate_out'] : '',
                    ['price_out'] : index > -1 ? formValues.inscription_out[index].price : 0,
                    ['m_price_out'] : index > -1 ? formValues.inscription_out[index].m_price : 0,
                    ['price_out_first'] : '',
                    ['active'] : true,
                    ['index'] : index > -1 ? index  : 0
                });
            } /*else if(name == "price_out"){
                
                setFormValues({
                    ...formValues,
                    ['price_out'] : value
                });
                
                let valorNew = parseInt(value);
   
                setTimeout(() => {
                    if(formValues.active == false && (valorNew == "" || valorNew <=0 || isNaN(valorNew))){
                        toastr.error('Error al calcular proporcional, valor de 1ra cuota debe ser número superior a 0', 'Error');
                    }else if (formValues.active == false && (formValues.nextduedate_out == "" || formValues.duedate_out == "")) {
                        toastr.error('Error al calcular proporcional, fecha inicio y 1ra cuota son obligatorios', 'Error');
                    } else if (formValues.active == false){
                        const differenceInTime = formValues.nextduedate_out[0].getTime() - formValues.duedate_out[0].getTime();
                        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
                        
                        if(differenceInDays > 0){
                            
                        }
                    }
                    
                }, 500);
                 
            }*/ else {
                setFormValues({
                    ...formValues,
                    [name]: value
                });
            }
        }
        
    };
    
    const tog_standardEdit = () => {
        setEdit(true);
        setmodal_standard(!modal_standard);
        removeBodyCss();
    };

    const tog_standard = () => {
        
       /* setFormValues({
            id: 0,
            nombre : '',
            rut: '',
            user_id: 0,
            course_id: 0,
            active: true,
            cycle: '',
            delete: 0
        });*/
         
        setmodal_standard(!modal_standard);
        removeBodyCss();
    };
    
    const tog_standard_new = () => {
 
        setNewInscripcion(!modal_standard_new);
        setmodal_standard_new(!modal_standard_new);
        removeBodyCss();
        
        if(!modal_standard_new){
            setFormValues({
                ...formValues,
                ['course_out']: 0,
                ['cycle_out'] : '',
                ['duedate_out'] : '',
                ['nextduedate_out'] : '',
                ['price_out'] : '',
                ['m_price_out'] : '',
                ['price_out_first'] : '',
                ['active'] : true,
                ['index'] : 0
            });
        }
        
    };

    const guardarNuevo = (e) => {
        
        e.preventDefault();
        toastr.options = {
            closeButton: true,
            progressBar: true,
            showEasing: 'linear',
            hideEasing: 'linear',
            hideDuration: 1
          };
        toastr.clear();
        setLoadingNew(true);
        
        let usuariosTmp = [];

        axios.post(`/inscriptions`, formValues)
            .then(
                (res) => {

                    if(!res.data || res.data.error){
                        toastr.error('Error interno al crear inscripcion', 'Error')
                    } else {
                       setUsuarios([]); 
                       for(let i=0;i<res.data.inscriptions.length;i++){
                            usuariosTmp.push(
                                {
                                   "id": res.data.inscriptions[i].user_id,
                                   "name": res.data.inscriptions[i].user_name,
                                   "fecha_nacimiento": res.data.inscriptions[i].fecha_nacimiento !== '0000-00-00' && res.data.inscriptions[i].fecha_nacimiento !== "" ? format(parseISO(res.data.inscriptions[i].fecha_nacimiento), 'dd-MM-yyyy') : '',
                                   "email": res.data.inscriptions[i].user_email,
                                   "rut": Format(res.data.inscriptions[i].rut),
                                   "cursos": res.data.inscriptions[i].course_names,
                                   "estado": (res.data.inscriptions[i].course_names != "") ? res.data.inscriptions[i].pagados : "",
                                   "activo":(res.data.inscriptions[i].active == 1) ? 'SI' : 'NO' 
                               }
                            );
                        }
                        setUsuarios(usuariosTmp);
                        setLoadingNew(false);
                        toastr.success(res.data.message, 'OK');
                        //tog_standard();
                        setFormValues({
                            ...formValues,
                            ['id']: 0,
                            //nombre : '',
                            //rut: '',
                            //user_id: 0,
                            ['course_id']: 0,
                            ['active']: true,
                            ['cycle']: '',
                            ['course_out']:0,
                            ['course_in']: 0,
                            ['delete']: 0,
                            ['index'] : 0,
                            ['inscription_in']: res.data.inscription_in,
                            ['inscription_out']: res.data.inscription_out
                        });
                      }
                })
            .catch((error) => {
                setLoadingNew(false);
                if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                   toastr.error(error.message, 'Error')  
                  }
             })

    };
    
     const actualizar = (e) => {
        
        e.preventDefault();
        toastr.options = {
            closeButton: true,
            progressBar: true,
            showEasing: 'linear',
            hideEasing: 'linear',
            hideDuration: 1
          };
        toastr.clear();
        setLoadingNew(true);
        
        let usuariosTmp = [];

        axios.put(`/inscriptions`, formValues)
            .then(
                (response) => {

                    if(!response.data || response.data.error){
                        toastr.error('Error interno al actualizar inscripciones', 'Error')
                    } else {
                       setUsuarios([]); 
                       for(let i=0;i<response.data.inscriptions.length;i++){
                            usuariosTmp.push(
                                {
                                    "id": response.data.inscriptions[i].user_id,
                                    "name": response.data.inscriptions[i].user_name,
                                    "fecha_nacimiento": response.data.inscriptions[i].fecha_nacimiento !== '0000-00-00' && response.data.inscriptions[i].fecha_nacimiento !== "" ? format(parseISO(response.data.inscriptions[i].fecha_nacimiento), 'dd-MM-yyyy') : '',
                                    "email": response.data.inscriptions[i].user_email,
                                    "rut": Format(response.data.inscriptions[i].rut),
                                    "cursos": response.data.inscriptions[i].course_names,
                                    "estado":  (response.data.inscriptions[i].course_names != "") ? response.data.inscriptions[i].pagados : "",
                                    "activo": (response.data.inscriptions[i].active == 1) ? 'SI' : 'NO' 
                               }
                            );
                        }
                        setUsuarios(usuariosTmp);
                        setLoadingNew(false);
                        toastr.success(response.data.message, 'OK');
                        //tog_standard();
  
                        setFormValues({
                            /*id: 0,
                            nombre : '',
                            rut: '',
                            user_id: 0,
                            course_id: 0,
                            active: true,
                            cycle: '',
                            course_out:0,
                            course_in: 0,
                            delete: 0,*/
                            ...formValues,
                            //index:0,
                            ['inscription_in']: response.data.inscription_in,
                            ['inscription_out']: response.data.inscription_out
                        });
                      }
                })
            .catch((error) => {
                setLoadingNew(false);
                if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                   toastr.error(error.message, 'Error')  
                  }
             })

    };
    
    const onFocusRut = (rut, name) => 
    {
        if (rut != ''){
            rut = clean(rut);
            setFormValues({
                ...formValues,
                [name]: rut,
              });
        }
    };
    
    const refreshTable = () => {
        setLoadingTable(true);
        setUsuarios([]);
        let usuariosTmp = [];
        axios.get('/inscriptions', {}).then((response) => {
            if(!response.data){
                toastr.error('Error interno al obtener información', 'Error');
                setLoadingTable(false);
            } else {
               for(let i=0;i<response.data.inscriptions.length;i++){
                    usuariosTmp.push(
                        {
                            "id": response.data.inscriptions[i].user_id,
                            "name": response.data.inscriptions[i].user_name,
                            "fecha_nacimiento": response.data.inscriptions[i].fecha_nacimiento !== '0000-00-00' && response.data.inscriptions[i].fecha_nacimiento !== "" ? format(parseISO(response.data.inscriptions[i].fecha_nacimiento), 'dd-MM-yyyy') : '',
                            "email": response.data.inscriptions[i].user_email,
                            "rut": Format(response.data.inscriptions[i].rut),
                            "cursos": response.data.inscriptions[i].course_names,
                            "estado": (response.data.inscriptions[i].course_names != "") ? response.data.inscriptions[i].pagados : "",
                            "activo": (response.data.inscriptions[i].active == 1) ? 'SI' : 'NO' 
                       }
                    );
                }
               setUsuarios(usuariosTmp);
               setLoadingTable(false);
            }
          }, (error) => {
        });  
    };
    
    const onBlurRut = (rut, name) => 
    {
        if (rut != '') {
            if (rut.length > 3) {
                rut = Format(rut);
                setFormValues({
                ...formValues,
                [name]: rut,
              });
            }
        } 
    };
    
    const handleConfirmarEmailCuota = () => 
    {
        //setLoadingTable(true);
        setCargandoMail(true);
        
        axios.get('/inscriptions/notification/'+notificaId ,{}).then((response) => {
 
            setEnvioOK(true);
            setCargandoMail(false);
            
               if(!response.data || response.data.error){
                    toastr.error('Error interno al enviar notificación', 'Error');
                    setLoadingTable(false);
               } else {
                   //toastr.error('Error interno al enviar notificación', 'Error');
                   setLoadingTable(false);
                }
            }, (error) => {
               // setEnvioOK(true);
                 setEnvioOK(true)
                 setLoadingTable(false);
                 if (error.response) {
                    if(Array.isArray(error.response.data.message)){
                        for(let i=0;i<error.response.data.message.length;i++){
                            toastr.error(error.response.data.message[i], 'Error')
                        }
                    } else {
                       toastr.error(error.response.data.message, 'Error')
                    }
                  } else if (error.request) {
                      toastr.error(error.request, 'Error')  
                  } else {
                   toastr.error(error.message, 'Error')  
                  }
           });  
    };
    
    
    const rowClickEmail = (cell) => 
    {
        setLoadingTable(true);
        let id = cell.row.cells[0].value;
        let nombre = cell.row.cells[2].value;
        axios.get('/inscriptions/history/'+id ,{}).then((response) => {
            if(!response.data || response.data.error){
            } else {
                setNotiAnteriores(response.data);
            }

             setEnvioOK(false);
             setNotificaId(id);
             setNotificaNombre(nombre);
             setConfirmarEmailModal(true);
             setLoadingTable(false);
             removeBodyCss();  
         }, (error) => {
              setLoadingTable(false);
              toastr.error('Error interno al obtener información', 'Error')
        });  
    };
    const rowClick = (cell) => 
    {
        let id = cell.row.cells[0].value;
        setLoadingTable(true);
        axios.get('/inscriptions/'+id ,{}).then((response) => {
               if(!response.data || response.data.error){
                    toastr.error('Error interno al obtener información', 'Error');
                    setLoadingTable(false);
               } else {
                   let usuario = response.data.inscription[0];
                   let nombre = usuario.user_name.split(' ');
                   let apellidos = '';
                   if(nombre.length >= 4){
                       nombre = usuario.user_name.split(' ')[0]+' '+usuario.user_name.split(' ')[1];
                       apellidos = usuario.user_name.split(' ')[2]+' '+usuario.user_name.split(' ')[3]
                   }else if(nombre.length == 3){
                       nombre = usuario.user_name.split(' ')[0];
                       apellidos = usuario.user_name.split(' ').slice(1).join(' ')+' '+usuario.user_name.split(' ').slice(2).join(' ');
                   }else{
                       nombre = usuario.user_name.split(' ')[0]+' '+usuario.user_name.split(' ')[1];
                   }
                  
                   setFormValues({
                        id: usuario.id,
                        rut: Format(usuario.rut),
                        nombre: nombre,
                        user_id: usuario.user_id,
                        course_id: 0,
                        course_in: 0,
                        course_out: 0,
                        active:  true ,
                        inscription_in: response.data.inscription_in,
                        inscription_out: response.data.inscription_out,
                        cycle_in: '',
                        index:0
                        
                    }); 
                    setLoadingTable(false);
                    tog_standardEdit();
                  }
            }, (error) => {
                 setLoadingTable(false);
                 toastr.error('Error interno al obtener información', 'Error')
           });  
        
    };
    
    const handleFilterChange = (columnId, value) => {
   
        setFilters({
          ...filters,
          [columnId]: value,
        });

        // Establece el filtro en la tabla
        setFilter(columnId, value);
      };

    
    const handleColumnSort = (column) => {

        let newSort = [];
        if(!column.isSorted && typeof column.isSortedDesc === 'undefined'){
             newSort = [{ id: column.id, desc: false }];
        }else if ( column.isSorted && column.isSortedDesc === false){
             newSort = newSort = [{ id: column.id, desc: true }];
        }else {
            newSort = newSort = [{ id: 'name', desc: false }];
        }
        setPaginaInicio(0);
        setSorting(newSort);
    };
    
    const handleCambioPagina = (valor) => {
        setPaginaInicio(valor);
    };

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
      };
    
      useEffect(() => {
          
        if(jsonString.indexOf('administrador') === -1){
            props.router.navigate('/pages-404');
            return;
        } 
    
        let usuariosTmp = [];
        axios.get('/inscriptions', {}).then((response) => {
               if(!response.data || response.data.error){
                   toastr.error('Error interno al obtener información', 'Error')
               } else {
                  for(let i=0;i<response.data.inscriptions.length;i++){
                       usuariosTmp.push(
                           {
                              "id": response.data.inscriptions[i].user_id,
                              "name": response.data.inscriptions[i].user_name,
                              "fecha_nacimiento": response.data.inscriptions[i].fecha_nacimiento !== '0000-00-00' && response.data.inscriptions[i].fecha_nacimiento !== "" ? format(parseISO(response.data.inscriptions[i].fecha_nacimiento), 'dd-MM-yyyy') : '',
                              "email": response.data.inscriptions[i].user_email,
                              "rut": Format(response.data.inscriptions[i].rut),
                              "cursos": response.data.inscriptions[i].course_names,
                              "estado": (response.data.inscriptions[i].course_names != "") ? response.data.inscriptions[i].pagados : "",
                              "activo": (response.data.inscriptions[i].active == 1) ? 'SI' : 'NO' 
                          }
                       );
                   }
                  setUsuarios(usuariosTmp);
                  setLoadingTable(false);
               }
             }, (error) => {
                  toastr.error('Error interno al obtener información', 'Error')
           });  
     }, []);
     
    const columns = useMemo(
        () => [
            {
                Header: 'ID',
                accessor: 'id'
            },
            {
                Header: 'RUT',
                accessor: 'rut'
            },
            {
                Header: 'Nombre',
                accessor: 'name'
            },
            {
                Header: 'Fecha Nacimiento',
                accessor: 'fecha_nacimiento'
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'Cursos',
                accessor: 'cursos'
            },
            {
                Header: 'Estado',
                accessor: 'estado'
            },
            {
                Header: 'Activo',
                accessor: 'activo'
            },
            {
                Header: 'Acciones',
                accessor: 'action',
                disableFilters: true,
                filterable: false,
                Cell: (cellProps) => {
                  return (
                    <div className="d-flex gap-3">
                        {cellProps.row.cells[6].value.includes('PENDIENTE') &&  
                        <div
                        style={{cursor:'pointer'}} 
                        className="text-success"
                        onClick={() => { rowClickEmail(cellProps); }}
                      >
                        <i style={{cursor:'pointer'}}  className="mdi mdi-mail font-size-18" id={'edita_'+cellProps.row.cells[0].value} />
                        <UncontrolledTooltip placement="top" target={'edita_'+cellProps.row.cells[0].value}>
                          Enviar Recordatorio de Pago
                        </UncontrolledTooltip>
                      </div>
                      }
                      <div
                        style={{cursor:'pointer'}} 
                        className="text-success"
                        onClick={() => { rowClick(cellProps); }}
                      >
                        <i style={{cursor:'pointer'}}  className="mdi mdi-pencil font-size-18" id={'notifica_'+cellProps.row.cells[0].value} />
                        <UncontrolledTooltip placement="top" target={'notifica_'+cellProps.row.cells[0].value}>
                          Editar
                        </UncontrolledTooltip>
                      </div>
                    </div>
                  );
                }
              },
        ],
        []
    );

    document.title = "Inscripciones | Listado";

    return (
            
        <div className="page-content">
        <ConfirmarEmailModal
            show={confirmarEmailModal}
            itemName={notificaNombre}
            notificaId={notificaId}
            envioOK={envioOK}
            notiAnteriores={notiAnteriores}
            cargandoMail={cargandoMail}
            onConfirmarEmailClick={handleConfirmarEmailCuota}
            onCloseClick={() => {setConfirmarEmailModal(false);setEnvioOK(true)}}
            />
        <DeleteModal
            show={deleteModal}
            itemName={itemName}
            onDeleteClick={handleDeleteUser}
            onCloseClick={() => setDeleteModal(false)}
          />
            <Container fluid={true}>
                <Breadcrumbs title="Usuarios" breadcrumbItem="Listado" />
                <Row>
            <Col className="col-12">
              <Card>
                <CardBody >
                  <CardTitle className="h4">Listado de Inscripciones</CardTitle>
                  {/*(usuarios  == "" ||*/ loadingTable/*) */ &&  
                  <div style={{ height: '65vh' }}  className="textoCentrado spinner-content-new" >
                  <Spinner className="ms-2" color="primary" />
                  </div>
                  }
                  {!loadingTable  &&
                  <div style={{ display: !loadingTable ? '' : 'none' }} className="table-hover">
                    <TableContainer
                      columns={columns}
                      data={usuarios}
                      handleRefreshClick={() => { refreshTable(); }}
                      handleUserClick={() => { tog_standard(); }}
                      isAddUserList={true}
                      handleExportCsvClick={() => { export_csv(); }}
                      customPageSizeOptions={true}
                      isGlobalFilter={false}
                      isAddOptions={false}
                      isRefresh={true}
                      sortParams={sorting}
                      handleFilterChange={(e,val) => { handleFilterChange(e,val); }}
                      handleColumnSort={(e) => { handleColumnSort(e); }}
                      customPageSize={10}
                      exportCsv="Exportar Listado"
                      className="custom-header-css"
                      rowClick={false}
                      handleCambioPagina={(e)=> { handleCambioPagina(e); }}
                      paginaInicio={paginaInicio}
                      handleRowClick={(e) => { rowClick(e); }}
                      />
                  </div>
                 }
                 <Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard();
                      }}
                      keyboard={false}
                      backdrop={loadingNew ? 'static' : true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModal">
                         {edit ? 'Editar Inscripción '+formValues.nombre : ''}
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setEdit(false);
                            setmodal_standard(false);
                          }}
                          disabled={loadingNew}
                          className="btn-close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                        </button>
                      </div>
                      <div className="modal-body">
                        <Form>
                            <div className="form-floating mb-3">
                                <Row>
                            <Col md={6}>
                                <div className="form-floating mb-3">
                                  <select disabled={loadingNew} name="course_in" onChange={handleChange} value={formValues.course_in} id="course_in" className="form-select">
                                    <option value="0">Seleccione</option>
                                    {formValues.inscription_in && formValues.inscription_in.map((val, key) => (
                                        <option key={key} value={val.curso_id}>{val.curso}</option>
                                    ))}
                                  </select>
                                  <label htmlFor="course_in">Cursos Inscritos</label>
                                </div>
                              </Col>
                              <Col md={6}>
                                <div className="form-floating mb-3">
                                  <button
                                    disabled={loadingNew}  
                                    type="button"
                                    onClick={() => {
                                      tog_standard()
                                      tog_standard_new();
                                    }}
                                    className="btn btn-success me-2"
                                    data-dismiss="modal"
                                  >
                                    Nueva Inscripción
                                  </button>
                                </div>
                              </Col>
                              </Row>
                            </div>
                            { formValues.course_in != 0 &&
                            <div className="form-floating mb-3">
                            <Row>
                                <Col md={12}>
                                <div className="form-floating mb-3">
                                  <select  
                                    disabled={true}
                                    readOnly={true}
                                     name="cycle_in" onChange={handleChange} value={formValues.cycle_in} id="cycle_in" className="form-select">
                                    <option value="0">Seleccione</option>
                                        <option value={"monthly"}>Mensual</option>
                                  </select>
                                  <label htmlFor="cycle_in">Seleccione Ciclo</label>
                                </div>
                              </Col>
                            </Row>
                            </div>
                            }
                            { formValues.course_in != 0 &&
                             <div className="form-floating mb-3">
                              <input 
                           
                              name="price_in" 
                              type="text" 
                              disabled={true}
                              readOnly={true}
                              onChange={handleChange} 
                              value={formValues.price_in} 
                              className="form-control" 
                              id="price_in" 
                              placeholder="Valor Cuota" />
                              <label htmlFor="price_in">Valor Cuota</label>
                            </div>
                            }
                            { formValues.course_in != 0 &&
                           <div className="form-floating mb-3">
                              <Flatpickr
                                id="duedate_in"
                               
                                onChange={e => {
                                    handleDateChange(e,'duedate_in');
                                  }}
                                value={formValues.duedate_in}
                                name="duedate_in"
                                disabled={true}
                                readOnly={true}
                                placeholder="dd M,yyyy"
                                className="form-control d-block"
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  locale: {
                                    ...Spanish,
                                        months: {
                                            ...Spanish.months,
                                            ...Spanish.longhand
                                        }
                                    },
                                }}
                              />
                              <Label htmlFor="duedate_in">Fecha de Inicio</Label>
                          </div>
                             }
                             { formValues.course_in != 0 &&
                             <div className="form-floating mb-3">
                              <input 
                              
                              name="m_price_in" 
                              type="text" 
                              disabled={true}
                              readOnly={true}
                              onChange={handleChange} 
                              value={formValues.m_price_in} 
                              className="form-control" 
                              id="m_price_in" 
                              placeholder="Valor Matrícula" />
                              <label htmlFor="m_price_in">Valor Matrícula</label>
                            </div>
                            }
                            { formValues.course_in != 0 &&
                           <div className="form-floating mb-3">
                              <Flatpickr
                                disabled={true}
                                readOnly={true}
                                id="nextduedate_in"
                                onChange={e => {
                                    handleDateChange(e,'nextduedate_in');
                                  }}
                                value={formValues.duedate_in}
                                name="nextduedate_in"
                                placeholder="dd M,yyyy"
                                className="form-control d-block"
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  locale: {
                                    ...Spanish,
                                        months: {
                                            ...Spanish.months,
                                            ...Spanish.longhand
                                        }
                                    },
                                }}
                              />
                              <Label htmlFor="nextduedate_in">Próxima Fecha Cobro</Label>
                          </div>
                             }
                             { formValues.course_in != 0 &&
                             <div className="form-floating mb-3">
                              <input 
                              
                              name="price_out_first_ref" 
                              type="text" 
                              disabled={true}
                              readOnly={true}
                              value={formValues.price_out_first} 
                              className="form-control" 
                              id="price_out_first_ref" 
                              placeholder="Valor 1ra Cuota" />
                              <label htmlFor="price_out_first_ref">Valor 1ra Cuota</label>
                            </div>
                            }
                          </Form>
                      </div>
                      <div className="modal-footer">
                      <div style={{
                            display: 'inline-block',
                            alignItems: 'center',}}>
                        <div style={
                            {margin: 10,
                            position: 'relative',}}>
                        {edit && 
                            <button
                          disabled={loadingNew || formValues.course_in == 0}
                          type="button"
                          onClick={() => {
                            onClickDelete(formValues.inscription_in[formValues.index], formValues.nombre);
                          }}
                          className="btn btn-danger me-2"
                        >
                          Borrar
                        </button>
                        }
                        <button
                          disabled={loadingNew}  
                          type="button"
                          onClick={() => {
                            setEdit(false);  
                            tog_standard();
                          }}
                          className="btn btn-secondary me-2"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        {!edit ? (
                        <button
                          disabled={loadingNew}
                          type="button"
                          disabled={loadingNew }
                          className="btn btn-primary "
                          onClick={e => {
                            guardarNuevo(e);
                          }}
                        >
                        <span style={{ visibility: !loadingNew ? 'visible' : 'hidden' }}>Guardar</span>
                        </button>)
                        : (<button
                          disabled={loadingNew || formValues.course_in == 0}
                          type="button"
                          className="btn btn-info "
                          onClick={e => {
                            actualizar(e);
                          }}
                        >
                        <span style={{ visibility: !loadingNew ? 'visible' : 'hidden' }}>Actualizar</span>
                        </button>)}
                        <Spinner style={{ display: loadingNew ? 'block' : 'none',
                        color: 'red',
                        '--bs-spinner-width':'1.7rem',
                        '--bs-spinner-height':'1.7rem',
                        position: 'absolute',
                        top: '46%',
                        left: '59%',
                        marginTop: -12,
                        marginLeft: -12}}  className="ms-2 " color="primary" />
                      </div>
                       </div>
                        </div>
                    </Modal>
                    <Modal
                      isOpen={modal_standard_new}
                      toggle={() => {
                        tog_standard_new();
                      }}
                      keyboard={false}
                      backdrop={loadingNew ? 'static' : true}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModal">
                         {newInscripcion ? 'Agregar Inscripción '+formValues.nombre : ''}
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setNewInscripcion(false);
                            setmodal_standard_new(false);
                          }}
                          disabled={loadingNew}
                          className="btn-close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                        </button>
                      </div>
                      <div className="modal-body">
                        <Form>
                            <div className="form-floating mb-3">
                                <Row>
                            <Col md={12}>
                                <div className="form-floating mb-3">
                                  <select disabled={loadingNew} name="course_out" onChange={handleChange} value={formValues.course_out} id="course_out" className="form-select">
                                    <option value="0">Seleccione</option>
                                    {formValues.inscription_out && formValues.inscription_out.map((val, key) => (
                                        <option key={key} value={val.curso_id}>{val.curso}</option>
                                    ))}
                                  </select>
                                  <label htmlFor="course_out">Seleccione Curso</label>
                                </div>
                              </Col>
                              </Row>
                            </div>
                            { formValues.course_out != 0 &&
                            <div className="form-floating mb-3">
                            <Row>
                                <Col md={12}>
                                <div className="form-floating mb-3">
                                  <select disabled={loadingNew} name="cycle_out" onChange={handleChange} value={formValues.cycle_out} id="cycle_out" className="form-select">
                                    <option value="0">Seleccione</option>
                                        <option value={"monthly"}>Mensual</option>
                                  </select>
                                  <label htmlFor="cycle_out">Seleccione Ciclo</label>
                                </div>
                              </Col>
                            </Row>
                            </div>
                            }
                            { formValues.course_out != 0 &&
                             <div className="form-floating mb-3">
                              <input 
                              disabled={loadingNew} 
                              name="price_out" 
                              type="text" 
                              onChange={handleChange} 
                              value={formValues.price_out} 
                              className="form-control" 
                              id="precio_out" 
                              placeholder="Valor cuota" />
                              <label htmlFor="price_out">Valor cuota</label>
                            </div>
                            }
                            { formValues.course_out != 0 &&
                           <div className="form-floating mb-3">
                              <Flatpickr
                                id="duedate_out"
                                disabled={loadingNew}
                                onChange={e => {
                                    handleDateChange(e,'duedate_out');
                                  }}
                                value={formValues.duedate_out}
                                name="duedate_out"
                                placeholder="dd M,yyyy"
                                className="form-control d-block"
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  locale: {
                                    ...Spanish,
                                        months: {
                                            ...Spanish.months,
                                            ...Spanish.longhand
                                        }
                                    },
                                }}
                              />
                              <Label htmlFor="duedate_out">Fecha de Inicio</Label>
                          </div>
                             }
                             { formValues.course_out != 0 &&
                             <div className="form-floating mb-3">
                              <input 
                              disabled={loadingNew} 
                              name="m_price_out" 
                              type="text" 
                              onChange={handleChange} 
                              value={formValues.m_price_out} 
                              className="form-control" 
                              id="m_price_out" 
                              placeholder="Valor matrícula" />
                              <label htmlFor="m_price_out">Valor matrícula</label>
                            </div>
                            }
                            { formValues.course_out != 0 &&
                           <div className="form-floating mb-3">
                              <Flatpickr
                                id="nextduedate_out"
                                disabled={loadingNew}
                                onChange={e => {
                                    handleDateChange(e,'nextduedate_out');
                                  }}
                                value={formValues.nextduedate_out}
                                name="nextduedate_out"
                                placeholder="dd M,yyyy"
                                className="form-control d-block"
                                options={{
                                  altInput: true,
                                  altFormat: "F j, Y",
                                  dateFormat: "Y-m-d",
                                  locale: {
                                    ...Spanish,
                                        months: {
                                            ...Spanish.months,
                                            ...Spanish.longhand
                                        }
                                    },
                                }}
                              />
                              <Label htmlFor="nextduedate_out">Fecha de 1ra cuota</Label>
                          </div>
                             }
                             { formValues.course_out != 0 &&
                             <div className="form-floating mb-3">
                              <input 
                              disabled={loadingNew} 
                              name="price_out_first" 
                              type="text" 
                              onChange={handleChange} 
                              value={formValues.price_out_first} 
                              className="form-control" 
                              id="price_out_first" 
                              placeholder="Valor 1ra cuota" />
                              <label htmlFor="price_out_first">Valor 1ra cuota</label>
                            </div>
                            }
                          </Form>
                      </div>
                      <div className="modal-footer">
                      <div style={{
                            display: 'inline-block',
                            alignItems: 'center',}}>
                        <div style={
                            {margin: 10,
                            position: 'relative',}}>
                        <button
                          disabled={loadingNew}  
                          type="button"
                          onClick={() => {
                            tog_standard_new();
                          }}
                          className="btn btn-secondary me-2"
                          data-dismiss="modal"
                        >
                          Cerrar
                        </button>
                        {newInscripcion ? (
                        <button
                          disabled={loadingNew || formValues.course_out == 0}
                          type="button"
                          className="btn btn-primary "
                          onClick={e => {
                            guardarNuevo(e);
                          }}
                        >
                        <span style={{ visibility: !loadingNew ? 'visible' : 'hidden' }}>Guardar</span>
                        </button>)
                        : (<button
                          disabled={loadingNew || formValues.course_out != 0}
                          type="button"
                          className="btn btn-info "
                          onClick={e => {
                            actualizar(e);
                          }}
                        >
                        <span style={{ visibility: !loadingNew ? 'visible' : 'hidden' }}>Actualizar</span>
                        </button>)}
                        <Spinner style={{ display: loadingNew ? 'block' : 'none',
                        color: 'red',
                        '--bs-spinner-width':'1.7rem',
                        '--bs-spinner-height':'1.7rem',
                        position: 'absolute',
                        top: '46%',
                        left: '59%',
                        marginTop: -12,
                        marginLeft: -12}}  className="ms-2 " color="primary" />
                      </div>
                       </div>
                        </div>
                    </Modal>
                </CardBody>
                </Card>
                </Col >
                 </Row>
            </Container>
        </div>
    );
}
InscripcionesList.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default withRouter(InscripcionesList);