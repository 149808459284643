import PropTypes from 'prop-types'
import React, {  useState } from "react"
import { Modal, ModalBody, Col,
  Row,
  Card,
  CardBody, Label  } from "reactstrap";
  //import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es.js"

import { format, parseISO  } from 'date-fns';

const ConfirmarModalEdit  = ({ show, onConfirmarClick, onCloseClick, cuota_editar, monto_editar, observacion_editar, onChangeMontoPago, onChangeObservacionPago }) => {

  return (
    <Modal size="md" isOpen={show} toggle={onCloseClick} centered={true}>
      <div className="modal-content">
        <ModalBody className="px-4 py-5 text-center">
          <button type="button" onClick={onCloseClick} className="btn-close position-absolute end-0 top-0 m-3"></button>
          <div className="avatar-sm mb-4 mx-auto">
            <div className="avatar-title bg-primary text-primary bg-opacity-10 font-size-20 rounded-3">
              <i className="bx bx-dollar-circle"></i>
            </div>
          </div>
          <p className="font-size-16 mb-3">¿Confirma modificar la cuota {cuota_editar}?</p>
          <Row>
            <Col xl={12} sm={12} xs={12}>
              <div className="mt-2 mb-4">
               <div className="form-floating mb-3">
                    <input name="monto_editar" 
                    onChange={e => {
                        onChangeMontoPago(e);
                      }}
                        value={monto_editar} type="text" className="form-control" id="monto_editar" placeholder="Ingrese valor" />
                    <label htmlFor="monto_editar">Valor Cuota</label>
                  </div> 
              </div>
            
            <div className="mt-2 mb-4">
                <div className="form-floating mb-3">
                    <input name="monto_editar" 
                    onChange={e => {
                        onChangeObservacionPago(e);
                      }}
                        value={observacion_editar} type="text" className="form-control" id="observacion_editar" placeholder="Ingrese observacion" />
                    <label htmlFor="observacion_editar">Observación (opcional)</label>
                  </div> 
              </div>
            
            
            </Col>
        </Row>
          <div className="hstack gap-2 justify-content-center mb-0">
            <button type="button" className="btn btn-danger" onClick={onConfirmarClick}>Modificar</button>
            <button type="button" className="btn btn-secondary" onClick={onCloseClick}>Cerrar</button>
          </div>
        </ModalBody>
      </div>
    </Modal>
  )
}

ConfirmarModalEdit .propTypes = {
  onCloseClick: PropTypes.func,
  onConfirmarClick: PropTypes.func,
  show: PropTypes.any,
  monto_editar: PropTypes.any,
  observacion_editar: PropTypes.any,
  onChangeMontoPago: PropTypes.func,
  onChangeObservacionPago: PropTypes.func
}

export default ConfirmarModalEdit 
