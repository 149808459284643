import PropTypes from "prop-types";
import React, { useMemo, useState, useEffect } from "react";
import { Row, Col, Alert, Card, CardBody, Container, FormFeedback, Input, Label, Form } from "reactstrap";
import {validate as Validate, clean, format as Format} from 'rut.js';

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { userForgetPassword } from "../../store/actions";

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";

const ForgetPasswordPage = props => {
    
  const [loading,setLoading] = useState(false);  
    
  const clearToast = () => {
    toastr.clear();
  }  

  //meta title
  document.title = "Forget Password | Skote - React Admin & Dashboard Template";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      rut: '',
    },
    validationSchema: Yup.object({
      rut: Yup.string().required("Por favor ingresa un RUT"),
    }),
    onSubmit: (values) => {
    setLoading(true);
    axios.post(`/reset-password`,{'rut':clean(values.rut)})
    .then(
        (res) => {
           setLoading(false);
           toastr.success(res.data.message, 'OK');
           validation.setFieldValue("rut", " ");
        })
    .catch((error) => {
        setLoading(false);
        if (error.response) {
              toastr.error(error.response.data.message, 'Error')
          } else if (error.request) {
               toastr.error(error.request, 'Error')
          } else {
               toastr.error(error.message, 'Error')
          }
         });
    }
  });
  
  const onBlurRut = (rut, name) => 
    {   
        if (rut != '') {
            if (rut.length > 3) {
                validation.setFieldValue("rut", Format(rut));
              };
            }
        };
    
    const onFocusRut = (rut, name) => 
    {  
        if (rut != ''){
            validation.setFieldValue("rut", clean(rut));
        }
    };

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="bx bx-home h2" />
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-softbg-soft-primary">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Skote.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {forgetError && forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="mb-3">
                        <Label className="form-label">RUT</Label>
                        <Input
                          name="rut"
                          className="form-control"
                          placeholder="Ingresa RUT"
                          type="text"
                          disabled={loading}
                          onChange={validation.handleChange}
                          onFocus={(e) => onFocusRut(e.target.value, e.target.name)}
                          onBlur={(e) => {
                            validation.handleBlur;
                            onBlurRut(e.target.value, e.target.name);
                          }}
                          value={validation.values.rut || ""}
                          invalid={
                            validation.touched.rut && validation.errors.rut ? true : false
                          }
                        />
                        {validation.touched.rut && validation.errors.rut ? (
                          <FormFeedback type="invalid">{validation.errors.rut}</FormFeedback>
                        ) : null}
                      </div>
                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            disabled={loading}
                            className="btn btn-primary w-md "
                            type="submit"
                          >
                            Recuperar
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  Regresar al {" "}
                  <Link to="/login" className="font-weight-medium text-primary">
                    Ingreso
                  </Link>{" "}
                </p>
                <p>
                  © {new Date().getFullYear()} Skote. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger" /> by Themesbrand
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
