import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
  postSocialLogin,
} from "../../../helpers/fakebackend_helper";

import {validate as Validate, clean, format as Format} from 'rut.js';

const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {
    if (process.env.MIX_REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      );
      yield put(loginSuccess(response));
    } else if (process.env.MIX_REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(postJwtLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    } else if (process.env.MIX_REACT_APP_DEFAULTAUTH === "fake") {
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }else if (process.env.MIX_REACT_APP_DEFAULTAUTH === "laravel") {
       const response = yield call(postFakeLogin, {
        rut: clean(user.rut),
        password: user.password,
      });
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
      
    }
    const jsonStringRedirect = (localStorage.getItem("authUser") && localStorage.getItem("authUser").length > 0) ? JSON.parse(JSON.parse(localStorage.getItem("authUser"))[0].rol) : [];
    if(jsonStringRedirect.indexOf('administrador') === -1){
        history('/cuotas');
    } else {
        history('/dashboard');
    }
  } catch (error) {
    if(error.response.data.message && error.response.data.message != ""){
        if(error.response.data.errors.rut && error.response.data.errors.rut.length > 0){
            yield put(apiError(error.response.data.errors.rut[0]));
        } else {
            yield put(apiError(error.response.data.message));
        }
    } else {
        yield put(apiError(error));
    }
  }
}

function* logoutUser({ payload: { history } }) {
  try {
      

    localStorage.removeItem("authUser");

    if (process.env.MIX_REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    } else if (process.env.MIX_REACT_APP_DEFAULTAUTH === "laravel") {
        axios.get('/checkloggin', {}).then((response) => {
          if(response.data && response.data.check == true){
              axios.post('/logout', {})
                .then(
                    (res) => {
                         //console.log(res);
                    })
                .catch(error =>  error.response);
          }
        }, (error) => {
            console.log(error);
        });
    }
    
    const elemento = document.getElementById('root');
    elemento.removeAttribute('data-pagotbk');
    elemento.removeAttribute('data-mensajeError');
    elemento.removeAttribute('data-mensajeOk');
    
    history('/login');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.MIX_REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      const response = yield call(fireBaseBackend.socialLoginUser, type);
      if (response) {
        history("/dashboard");
      } else {
        history("/login");
      }
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    else {
      const response = yield call(postSocialLogin, data);
      localStorage.setItem("authUser", JSON.stringify(response));
      yield put(loginSuccess(response));
    }
    history("/dashboard");
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeLatest(SOCIAL_LOGIN, socialLogin);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
